@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

$border-radius: 8px;
$box-sizing: border-box;
$font-size: 16px;
$line-height: 100%;
$cursor: pointer;
$opacity: 0.3;

@mixin disableButton {
  opacity: $opacity;
  cursor: not-allowed;
  pointer-events: none;
}

.btn {
  @include mixins.font(700);
  border-radius: $border-radius;
  box-sizing: $box-sizing;
  line-height: $line-height;
  font-size: $font-size;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary-btn {
  background-color: colors.$blue-charlie;
  color: colors.$white;
  border-style: none;
  &:hover {
    background-image: colors.$blue-delta;
    cursor: $cursor;
  }
  &:active {
    background-image: colors.$blue-echo;
    cursor: $cursor;
  }
  &:focus {
    background-color: colors.$blue-charlie;
    box-shadow: 0px 0px 0px 4px colors.$blue-foxtrot;
  }
  &:disabled {
    @include disableButton();
    background-color: colors.$blue-charlie;
  }
}

.secondary-btn {
  background-color: colors.$dark-gray-bravo;
  color: colors.$white;
  border-style: none;
  &:hover {
    background-color: colors.$dark-gray-charlie;
    cursor: $cursor;
  }
}

.basic-btn {
  background-color: colors.$white;
  color: colors.$dark-gray-bravo;
  border: 1px solid colors.$light-gray-alpha;
  &:hover {
    background-color: colors.$light-gray-bravo;
    cursor: $cursor;
  }
  &:active {
    background-color: colors.$light-gray-alpha;
    cursor: $cursor;
  }
  &:focus {
    background-color: colors.$white;
    box-shadow: 0px 0px 0px 4px colors.$dark-gray-delta;
  }
  &:disabled {
    @include disableButton();
    background-color: colors.$white;
  }
}

.auto-small-btn-width {
  width: auto;
  width: 28px;
  height: 28px;
}

.auto-btn-width {
  width: auto;
  padding: 12px 24px;
}

.auto-icon-btn-width {
  width: auto;
  padding: 14px;
}

.search-bar-btn {
  width: auto;
  background-color: transparent;
  display: inline;
}
