@use '../../global-styles/colors';
@use '../../global-styles/mixins';
@use '../../global-styles/breakpoints';

.flex-align-center {
  align-items: center;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    flex-direction: column-reverse;
    margin-left: 0px;
    & h1 {
      font-size: 28px;
      margin-top: 20px;
    }
    & p {
      font-size: 12px;
    }
  }
}
.project-sub-heading {
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    flex-direction: column;
    align-items: center;
  }
}

.project-top-project-detail {
  max-width: 45vw;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    max-width: unset;
  }
}

.banner-padding {
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    padding: 32px 12px;
  }
}
.default-property-card {
  height: 120px;
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    max-width: none;
    width: 100%;
    flex: 0.5;
    margin-right: 16px;
    height: 64px;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0px 24px;
    & h3 {
      font-size: 24px;
    }
    & > p {
      margin: 0px;
    }
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex: unset;
    margin-right: unset;
  }
}
.default-progress-card {
  height: 120px;
  width: auto;
  max-width: 694px;
  display: flex;
  padding: 0px 32px;
  flex-direction: column;
  justify-content: space-evenly;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    margin: 12px 0px;
  }
}
.width-auto-progress-card {
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: auto;
  }
}
.project-detail-img-container {
  width: 66%;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    margin: 40px 0px;
    justify-content: center;
    flex-direction: column-reverse;
    width: 100%;
    gap: 40px;
    align-items: unset;
  }
  & > img {
    width: 100%;
    height: 372px;
  }
  & .project-detail-img-container-property-section {
    width: -webkit-fill-available;
    max-width: 694px;
  }
  & .project-detail-warning {
    max-width: unset;
  }
}
.company-card {
  background-color: colors.$light-gray-foxtrot;
  height: fit-content;
  margin-left: 32px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 100%;
    max-width: 100%;
    margin: 32px 0px 0px 0px;
    & > p {
      margin-left: 32px;
    }
  }

  &.position-sticky {
    position: sticky;
    top: 150px;
  }
}
.company-card-top-section {
  margin: 32px 32px 16px 32px;
  align-items: center;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    justify-content: center;
    align-items: center;
    & h3 {
      font-size: 24px;
    }
  }
}
.company-card-btn {
  width: 85%;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: inherit;
  }
}
.company-card-container {
  margin: 32px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.company-property-card {
  background-color: colors.$white;
  height: 105px;
  width: 166px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:first-child {
    margin-right: 16px;
  }
  &:nth-child(3) {
    margin-right: 16px;
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    &:first-child {
      margin-right: 0px;
    }
    &:nth-child(3) {
      margin-right: 0px;
    }
    width: 130px;
    height: 85px;
    & h3 {
      font-size: 24px;
    }
  }
}
.company-card-checkbox-section {
  row-gap: 16px;
  & span {
    color: #2c3941;
  }
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    & span {
      font-size: 12px;
    }
  }
}
.detail-layout {
  justify-content: space-between;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    flex-direction: column;
  }
}

.top-company-details {
  width: 90%;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: 100%;
    margin: 0px;
    & p {
      text-align: center;
    }
    & h2 {
      text-align: center;
    }
  }
}

.about-company-details {
  width: 60%;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: 100%;
    margin: 0px;
    & p {
      text-align: center;
    }
    & h2 {
      text-align: center;
    }
  }
}

.deal-contour-info {
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: 100%;
    margin-top: 80px;
    margin-left: 0px;
  }
}
.key-investors-info {
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: 100%;
    margin-top: 80px;
    margin-left: 0px;
  }
}
.project-sub-info {
  & a:nth-child(1) {
    cursor: auto;
  }

  .tag-container:last-child {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: breakpoints.$breakpoint-large-screen) {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
.client-engagement {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    padding: 0px;
  }
}
.project-cover-image {
  max-width: 634px;
  max-height: 500px;
  border-radius: 10px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    max-width: 330px;
  }
}

.client-engagement-images {
  width: auto;
  height: auto;
  max-height: 150px;
  max-width: 350px;
}

.client-engagement-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    justify-content: center;
  }
}

.investors-backed {
  align-items: center;
  p {
    font-size: 16px;
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
    margin-left: 0px;
  }
}

.back-button-container {
  align-items: center;
  .back-button {
    font-weight: 700;
    color: colors.$blue-charlie;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    visibility: hidden;
  }
}

.project-detail-main-layout {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-left: 0px;
  }
}

.combo-button {
  button {
    width: 100%;
  }
}

.similar-projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  max-width: 90vw;
  margin-bottom: 32px;
  gap: 27px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 12px;
    justify-content: flex-start;
    gap: 27px;
    & img {
      width: -webkit-fill-available;
    }
    & .project-card-container {
      overflow: visible;
      max-width: 412px;
    }
  }
}

.similar-projects-title {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    text-align: center;
  }
}

.width-auto {
  width: auto;
}

.project-section-switcher-card {
  background-color: colors.$white;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  transition: padding 0.3s ease;

  &.shrunk {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: auto;
    // margin: 32px 0px 0px 0px;
    padding: 0;

    &.shrunk {
      padding: 0;
    }

    & > p {
      margin-left: 32px;
    }
  }
}

.about-section-images-card {
  background-color: colors.$light-gray-foxtrot;
  padding: 20px 15px;
  max-width: 95%;
}

.project-about-preview-image {
  max-width: 190px;
  max-height: 86px;
  border-radius: 5px;
}

.term-card-container {
  border-bottom: 1px solid;
  justify-content: space-between;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-top: 10px;
    flex-wrap: wrap;
  }
}

.term-card {
  background-color: colors.$white;
  height: 105px;
  width: 166px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border: 0px;
  &:first-child {
    margin-right: 16px;
  }
  &:nth-child(3) {
    margin-right: 16px;
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    &:first-child {
      margin-right: 0px;
    }
    &:nth-child(3) {
      margin-right: 0px;
    }
    width: 130px;
    height: 85px;
    & h3 {
      font-size: 24px;
    }
  }
}

.property-card-container {
  width: 100%;
  border-top: 1px solid;
  column-gap: 50px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    justify-content: space-between;
  }
  flex-wrap: wrap;
}

.property-card {
  height: 55px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border: 0px;
  margin: 10px 10px 0 0;
}

.position-sticky-top-86 {
  position: sticky;
  top: 86px;
}

.position-sticky-top-20 {
  position: sticky;
  top: 20px;
}

.z-10 {
  z-index: 10;
}

.highlights-container {
  & li {
    list-style-type: square;
    font-size: 15px;
    margin-bottom: 16px;
  }
}

.opportunity-details-heading {
  color: colors.$blue-charlie;
}
