// we will follow NATO Phonetic Alphabet to differentiate 2 same color by using postfix.
// like Alpha,Bravo,Charlie,Delta,Echo,Foxtrot,Golf,Hotel,India,Juliet,Kilo,Lima,Mike,November,Oscar,Papa,Quebec,Romeo,Sierra,Tango,Uniform,Victor,Whiskey,X-ray,Yankee,Zulu

// Primary Color
$red-alpha: #f53b4d;
$red-bravo: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15), #f53b4d);
$red-charlie: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15), #f53b4d);
$red-delta: #ffe5e8;
$red-echo: rgba(245, 59, 77, 0.4);
$red-foxtrot: rgba(221, 0, 0, 0.1);

// New Primary Color
$blue-charlie: #0f4f99;
$blue-delta: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15), #0f4f99);
$blue-echo: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15), #0f4f99);
$blue-foxtrot: rgba(15, 19, 155, 0.4);

// Secondary Color
$white: #ffffff;
$light-gray-alpha: #e2e2e2;
$light-gray-bravo: #f7f7f7;
$light-gray-charlie: rgba(255, 255, 255, 0.01);
$light-gray-delta: #dcdcdc;
$light-gray-echo: rgba(84, 90, 94, 0.7);
$light-gray-foxtrot: #f9f9f9;
$light-gray-golf: #e9e1f8;
$light-gray-hotel: #e1e1e1;
$light-gray-india: #ccc;
$light-gray-juliet: rgba(226, 226, 226, 0.5);
$light-gray-kilo: #e0e0e0;
$light-gray-lima: #cacaca;

// New secondary color
$golden: #caa566;

// Dark gray color
$dark-gray-alpha: #545a5e;
$dark-gray-bravo: #2c3941;
$dark-gray-charlie: #2e3349;
$dark-gray-delta: rgba(14, 14, 14, 0.05);

// Status Color
$success: #48b767;
$error: #dd0000;

// Blue color
$blue-alpha: #247cff;
$blue-bravo: #e5efff;

// Orange color
$orange-alpha: #f2a32d;
$orange-bravo: #fff5e5;
$orange-charlie: rgba(242, 163, 45, 0.1);

// Input label color
$black: #000000;

// Green color
$green-alpha: #48b767;
$green-bravo: #e0fee9;
$green-charlie: #4bb543;
