@use '../../global-styles/colors';

.legal-card-container {
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  padding: 32px;
  min-width: 85vw;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.legal-dropdown-container {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.w-50 {
  width: 50%;
}
