@use '../../global-styles/colors';
@use '../../global-styles/mixins';
@use '../../global-styles/breakpoints';

$spacing-section-desktop: 96px;
$spacing-section-tablet: 56px;

.about-us-main-layout {
  padding: 0 10px;

  &__header-section {
    margin-top: 56px;

    &__heading {
      font-size: 64px;

      @media (max-width: breakpoints.$breakpoint-large) {
        text-align: center;
        font-size: 42px;
      }
    }
    &__hero-image {
      width: 130%;
      @media (max-width: breakpoints.$breakpoint-large) {
        margin-top: 40px;
        width: 110%;
      }
    }

    // align-items: center;
    @media (max-width: breakpoints.$breakpoint-large) {
      margin-top: 25px;
      flex-direction: column;
    }
  }

  &__team-section {
    margin-top: $spacing-section-desktop;

    &__stakeholder {
      width: 360px;
      justify-content: center;

      & p {
        text-align: justify;
      }
    }

    @media (max-width: breakpoints.$breakpoint-large) {
      margin-top: $spacing-section-tablet;
    }
  }

  &__values-section {
    margin-top: $spacing-section-desktop;

    margin-left: 30px;
    margin-right: 30px;

    &__values-container {
      text-align: center;
      display: flex;
      justify-content: space-evenly;

      &__column {
        align-items: start;
        width: 45%;

        & p {
          text-align: justify;
        }

        @media (max-width: breakpoints.$breakpoint-large) {
          width: 100%;
        }
      }

      @media (max-width: breakpoints.$breakpoint-large) {
        margin-top: $spacing-section-tablet;
        margin-left: 0;
        margin-right: 0;
      }
    }

    @media (max-width: breakpoints.$breakpoint-large) {
      margin-top: $spacing-section-tablet;
    }
  }

  &__mission-section {
    margin-top: $spacing-section-desktop;

    margin-left: 30px;
    margin-right: 30px;

    &__mission-container {
      text-align: center;
      display: flex;
      justify-content: space-evenly;

      &__column {
        align-items: start;
        width: 45%;

        & p {
          text-align: justify;
        }

        @media (max-width: breakpoints.$breakpoint-large) {
          width: 100%;
        }
      }

      @media (max-width: breakpoints.$breakpoint-large) {
        width: 100%;
        flex-direction: column;
      }
    }

    @media (max-width: breakpoints.$breakpoint-large) {
      margin-top: $spacing-section-tablet;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__vision-section {
    margin-top: $spacing-section-desktop;

    margin-left: 30px;
    margin-right: 30px;

    &__vision-container {
      text-align: center;
      display: flex;
      justify-content: space-evenly;

      &__column {
        align-items: start;
        width: 45%;
        & p {
          text-align: justify;
        }

        @media (max-width: breakpoints.$breakpoint-large) {
          width: 100%;
        }
      }

      @media (max-width: breakpoints.$breakpoint-large) {
        width: 100%;
        flex-direction: column;
      }
    }

    @media (max-width: breakpoints.$breakpoint-large) {
      text-align: center;
      margin-top: $spacing-section-tablet;
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (max-width: breakpoints.$breakpoint-large) {
    margin: 0;
  }
}
.column-data {
  @media (max-width: breakpoints.$breakpoint-large) {
    text-align: center;
  }
}

.about-us-cover {
  border-radius: 10px;

  &-container {
    position: relative;

    gap: 60px;

    @media (max-width: breakpoints.$breakpoint-tablet) {
      text-align: center;
      flex-direction: column;
    }
  }
}

.about-us-team {
  margin-top: 120px;

  &-heading {
    font-weight: 400;
  }

  &-avatar {
    @media (max-width: breakpoints.$breakpoint-tablet) {
      width: 80%;
    }
  }
}

.about-us-cover-background {
  position: absolute;
  background-image: url(https://gbc-credibila-assets.s3.amazonaws.com/images/home-cover-bg.png);
  background-repeat: round;

  width: calc(100% + 72 * 2px);
  height: calc(100% + 50px);
  left: -72px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    background-repeat: no-repeat;
  }
}

.about-us-wrapper-alpha {
  position: relative;
  background-color: #f7f7f7;

  left: -72px;
  padding: 60px 72px;
  width: 100%;
}

.about-us-wrapper-bravo {
  position: relative;
  background-color: white;

  left: -72px;
  padding: 60px 72px;
  width: 100%;
}

.about-us-img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.about-us-linkedin {
  height: 20px;
  width: 20px;
  margin-left: 40px;
  cursor: pointer;
}

.about-us-content {
  &-container-alpha {
    @media (max-width: breakpoints.$breakpoint-tablet) {
      flex-direction: column;
    }
  }

  &-container-bravo {
    @media (max-width: breakpoints.$breakpoint-tablet) {
      flex-direction: column-reverse;
    }
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    text-align: center;

    p {
      text-align: center;
    }
  }
}
