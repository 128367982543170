@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

$cursor: pointer;
.basic-anchor {
  @include mixins.font(700);
  font-size: 16px;
  line-height: 120%;
  color: colors.$blue-alpha;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    cursor: $cursor;
  }
}

.label-anchor {
  @include mixins.font(400);
  font-size: 14px;
  line-height: 100%;
  background-color: colors.$white;
  border: 1px solid colors.$light-gray-alpha;
  color: colors.$dark-gray-bravo;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 8px;
  &:hover {
    cursor: $cursor;
  }
  & span {
    margin-left: 8px;
  }
}
.nav-link {
  @include mixins.font(400);
  font-size: 16px;
  line-height: 120%;
  color: colors.$dark-gray-alpha;
  text-decoration: underline;
  &-bold {
    @include mixins.font(600);
    font-size: 16px;
    line-height: 120%;
    color: colors.$dark-gray-alpha;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      cursor: $cursor;
      color: colors.$blue-charlie;
    }
    &:active {
      text-decoration: none;
      cursor: $cursor;
      color: colors.$dark-gray-alpha;
    }
  }
  &:hover {
    text-decoration: none;
    cursor: $cursor;
    color: colors.$blue-charlie;
  }
  &:active {
    text-decoration: none;
    cursor: $cursor;
    color: colors.$dark-gray-alpha;
  }
}

.opportunity-detail-document-anchor {
  @include mixins.font(400);
  color: colors.$dark-gray-alpha;
}
