@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

.radio-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  & > div {
    display: flex;
    align-items: center;
  }
  & .radio-heading {
    @include mixins.font(700);
    font-size: 14px;
    line-height: 140%;
    color: colors.$black;
  }
}

.radio-label {
  @include mixins.font(400);
  font-size: 14px;
  line-height: 150%;
  color: colors.$dark-gray-alpha;
  margin-left: 12px;
}
input[type='radio'] {
  border: 1px solid colors.$light-gray-alpha;
  width: 24px;
  height: 24px;
}
input:checked {
  border: 1px solid colors.$dark-gray-alpha;
  background-color: 'green';
}
