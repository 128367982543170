@use '../../global-styles/colors';
@use '../../global-styles/mixins';
@use '../../global-styles/breakpoints';

.contact-us-main-layout {
  &__heading {
    @media (max-width: breakpoints.$breakpoint-tablet) {
      text-align: center;
    }
  }

  &__form {
    margin: 0 60px;

    @media (max-width: breakpoints.$breakpoint-tablet) {
      margin: 0 10px;
    }

    & form {
      width: 100%;
    }
  }
}

.contact-us-form-error-message-wrapper {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.field-container {
  width: 100%;
}

.contact-us-input {
  &-row {
    display: flex;
    justify-content: space-between;
  }

  &-group {
    width: 47%;
  }
}

.g-captcha {
  position: relative;

  width: 45%;
  left: -9%;
  transform: scale(0.6);
}

.contact-us-submit {
  width: 47%;
}

.contact-us-card-container {
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  width: 100%;
  height: auto;
  display: inline-block;
  @media (max-width: breakpoints.$breakpoint-mobile) {
    max-width: none;
    width: auto;
  }
}

.contact-us-modal-container {
  max-width: 600px;
  width: 80%;
}
