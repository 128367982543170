@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';
@use '../../../global-styles/breakpoints';

.featured-container {
  @include mixins.font(700);
  display: flex;
  flex-direction: column;
  min-width: 95vw;
  align-items: center;
  margin-top: 29px;
}

.featured-image {
  min-width: 94vw;
  height: 324px;
  border-radius: 16px;
  @media (max-width: breakpoints.$breakpoint-mobile) {
    max-width: 94vw;
  }
}

.featured-top {
  display: flex;
  justify-content: space-between;
  min-width: 80vw;
  margin-bottom: 36px;
  gap: 30px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 32px;
  }
}

.featured-top-left {
  display: flex;
  flex-direction: column;
  color: colors.$dark-gray-bravo;
  min-width: 354px;

  .featured-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: colors.$dark-gray-alpha;
  }
  strong {
    font-size: 20px;
    font-weight: 700;
    color: colors.$dark-gray-bravo;
    margin-left: 12px;
  }
  @media (max-width: breakpoints.$breakpoint-mobile) {
    min-width: auto;
  }
}

.featured-top-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 522px;
  .project-description {
    color: colors.$dark-gray-alpha;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: right;

    @media (max-width: breakpoints.$breakpoint-tablet) {
      align-items: center;
      text-align: center;
    }
  }
  @media (max-width: breakpoints.$breakpoint-mobile) {
    align-items: center;
    text-align: center;
    max-width: fit-content;
  }
}

.featured-btn-container {
  display: flex;
  gap: 15px;
  @media (max-width: breakpoints.$breakpoint-mobile) {
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.featured-stats-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin-top: 4px;
  height: 46px;
  @media (max-width: breakpoints.$breakpoint-mobile) {
    justify-content: center;
  }
}

.vertical-separator {
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 6px;
  height: 26px;
}

.tags-container {
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
  color: colors.$white;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    justify-content: center;
  }
}
