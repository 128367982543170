@use '../../global-styles/breakpoints';

.self-declaration-container {
  h2,
  p {
    @media (max-width: breakpoints.$breakpoint-tablet) {
      text-align: center;
    }
  }
  .primary-btn {
    @media (max-width: breakpoints.$breakpoint-tablet) {
      width: 100%;
    }
  }
}

.self-declaration-wrapper {
  max-width: 854px;
  max-height: 469px;
  background: #f9f9f9;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  padding: 24px;

  &-overflow {
    overflow: auto;
  }
}

.self-declaration-heading {
  margin-bottom: 48px;

  p {
    margin-top: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #2c3941;
  }
}
.self-declaration-sub-heading {
  margin-bottom: 29px;
  font-size: 18px;
  font-weight: 600;
  color: #2c3941;
}

.self-declaration-muted-wrapper {
  position: absolute;
  width: 100%;
  height: 150px;
  background: #f9f9f9bd;
  bottom: 0;
  right: 0;
  left: 0;
}

.self-declaration-expand-button {
  width: 48px;
  height: 48px;
  font-size: 30px;
  font-weight: 100;
}

.self-declaration-footer {
  margin-top: 25.5px;
}

.self-declaration-wrapper-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #545a5e;
}
