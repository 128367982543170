@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';
@use '../../../global-styles/breakpoints';

.project-cover-video {
  width: 634px;
  max-height: 500px;
  border-radius: 10px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 100%;
    max-height: 330px;
  }
}
