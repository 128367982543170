@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

/* no default bullets */
.list {
  &-item {
    list-style-type: none;
    @include mixins.font(400);
    font-size: 16px;
    line-height: 150%;
    color: colors.$dark-gray-alpha;
    margin-bottom: 16px;
    padding-left: 16px !important;
  }
  &-item::marker {
    content: url(rectangle.png);
  }
}
