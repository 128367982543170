@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';
@use '../../../global-styles/breakpoints';

.tabs-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.tab-link {
  @include mixins.font(700);
  font-size: 16px;
  line-height: 120%;
  color: colors.$dark-gray-bravo;
  text-decoration: none;
  display: flex;
  padding-left: 16px;
  align-items: center;
  width: 299px;
  height: 62px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 97%;
  }
}

.activeTab {
  background: colors.$light-gray-juliet;
  border-radius: 8px;
}
.tab-active-container {
  width: 100%;
  margin-left: 145px;
  @media (max-width: breakpoints.$breakpoint-large-screen) {
    margin-left: 40px;
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-left: 0px;
    margin-top: 32px;
  }
}
