@use '../../global-styles/breakpoints';
@use '../../global-styles/colors';
@use '../../global-styles/mixins';

.invest {
  &-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
    &-project-title {
      color: colors.$blue-charlie;
    }
    &-heading {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      row-gap: 12px;
      @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 12px;
      }
      @media (max-width: breakpoints.$breakpoint-tablet) {
        text-align: center;
        align-items: center;
      }
    }
    &-subheading {
      display: flex;
      justify-content: space-between;
      @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
        flex-direction: column;
        row-gap: 12px;
      }
    }
    @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 24px;
    }
  }
}
.invest-amount-project-card {
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  display: inline-block;
  padding: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    text-align: center;
  }
  &-detail {
    width: 80%;
    text-align: center;
    @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
      width: 90%;
    }
  }
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    padding: 32px;
  }
}
.invest {
  &-amount-container {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    gap: 32px;
    @media (max-width: breakpoints.$breakpoint-large-screen) {
      flex-direction: column;
      column-gap: 12px;
    }
    &-question-card {
      border: 1px solid colors.$light-gray-alpha;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 22px;

      @media (max-width: breakpoints.$breakpoint-tablet) {
        width: 22px;
        padding: 22px;
      }
    }
  }
}
.invest-amount-container-card {
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  display: inline-block;
  padding: 32px;
  display: flex;
  column-gap: 32px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
    row-gap: 32px;
    align-items: center;

    & > p {
      text-align: center;
    }
  }
}
.invest-amount-card {
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  display: inline-block;
  padding: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  & .dollar-input-icon {
    margin-left: 19px;
    position: absolute;
    margin-top: 16px;
    color: rgba(84, 90, 94, 0.7);
  }
  & input {
    padding-left: 33px;
  }
  & .info-basic-label {
    font-style: italic;
    font-size: 10px;
  }
}
.invest-amount-column-container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 50%;
  @media (max-width: breakpoints.$breakpoint-large-screen) {
    width: auto;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    text-align: center;
  }
}
.amount-input-card {
  @include mixins.font(400);
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  display: inline-block;
  font-size: 14px;
  line-height: 100%;
  color: colors.$dark-gray-alpha;
  padding: 6px 12px;
  &:hover {
    background-color: colors.$blue-charlie;
    border: 1px solid colors.$blue-charlie;
    color: white;
    cursor: pointer;
  }
}
.amount-list-container {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  justify-content: space-between;
  overflow: auto;
}
.stepper-btn-container {
  display: flex;
  gap: 15px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 100%;
  }
}
.btn-width-fit-content {
  width: fit-content;
  padding: 12px 24px;
}

.accept-tob {
  width: 20px;
  height: 20px;
}

.accept-tob-link {
  color: colors.$blue-charlie;
}

.agreement-disclaimer {
  background-color: colors.$orange-bravo;
  color: colors.$orange-alpha;

  padding: 15px;
  border-radius: 5px;
  width: fit-content;
  font-weight: bold;
}

.modal-opener-link {
  font-size: 16px;
  line-height: 120%;
  color: colors.$blue-charlie;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}
