@use '../../global-styles/colors';
@use '../../global-styles/breakpoints';
@use '../../global-styles/mixins';

.kyc-container {
  margin-left: 22px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-left: 0;
  }
}
.kyc-container-profile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-left: 0;
  }
}
.kyc-sub-heading {
  color: colors.$orange-alpha;
  max-width: 477px;
  background-color: colors.$orange-charlie;
  padding: 16px 12px;
  border-radius: 8px;
  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
  }
}

.dropdown-container {
  display: flex;
  align-items: center;
  gap: 32px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
  }
}

.kyc-footer {
  & .cta-banner-container {
    min-height: 100px;
    padding: 30px;
    @media (max-width: breakpoints.$breakpoint-mobile) {
      padding: 30px;
    }
  }
}

.error-message {
  color: colors.$error;
}
@mixin basic-kyc-status {
  @include mixins.font(700);
  font-size: 16px;
  line-height: 120%;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 6px;
}
.kyc-status-container {
  display: flex;
  flex-direction: column;
  &-label {
    @include mixins.font(700);
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: colors.$black;
  }
  &-status-NOT_STARTED {
    @include basic-kyc-status();
    color: colors.$orange-alpha;
  }
  &-status-INITIATED {
    @include basic-kyc-status();
    color: colors.$orange-alpha;
  }
  &-status-APPROVED {
    @include basic-kyc-status();
    color: colors.$success;
  }
  &-status-PENDING {
    @include basic-kyc-status();
    color: colors.$orange-alpha;
  }
  &-status-SENT_FOR_APPROVAL {
    @include basic-kyc-status();
    color: colors.$orange-alpha;
  }
  &-status-REJECTED {
    @include basic-kyc-status();
    color: colors.$error;
  }
  &-status-RESUBMIT {
    @include basic-kyc-status();
    color: colors.$error;
  }
}
.kyc-entity-title {
  @include mixins.font(600);
  font-size: 18px;
  line-height: 150%;
  color: #2c3941;
}
.kyc-sub-heading-text {
  @include mixins.font(400);
  font-size: 18px;
  line-height: 150%;
  color: #2c3941;

  &-container {
    background-color: colors.$orange-bravo;
    color: colors.$orange-alpha;

    padding: 15px;
    border-radius: 5px;

    &-note {
      background-color: colors.$light-gray-bravo;
      padding: 15px;
      border-radius: 5px;
    }
  }

  &-note {
    font-size: 14px;
  }

  &-disclaimer {
    font-weight: bold;
  }
}

.kyc-link {
  color: colors.$blue-charlie;
}

.modal-title-icon {
  align-items: center;
}
.kyc-entity-info-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rotate {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
