@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

.banner-container {
  @include mixins.font(700);
  font-size: 16px;
  line-height: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 22;
  top: 0;
  width: 100%;
  padding: 0px 12px;
  & span {
    margin: 16px 12px;
  }
}
.warning-banner {
  background-color: colors.$orange-bravo;
  color: colors.$orange-alpha;
}
.error-banner {
  background-color: colors.$red-delta;
  color: colors.$blue-charlie;
}
.success-banner {
  background-color: colors.$green-bravo;
  color: colors.$green-alpha;
}
