@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';
@use '../../../global-styles/breakpoints';

.investment-main-container {
  @include mixins.font(700);
  display: flex;
  flex-direction: column;
  width: inherit;
  overflow: hidden;

  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  padding: 32px;
  margin-top: 24px;

  &:hover {
    background-color: colors.$light-gray-foxtrot;
  }
}

.investment-stats-container {
  display: flex;
  gap: 16px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
  }

  .investment-stats-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    border: 1px solid colors.$light-gray-alpha;
    border-radius: 8px;
    width: 138px;
    height: 81px;
    @media (max-width: breakpoints.$breakpoint-tablet) {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-around;
    }
    .investment-card-main {
      transition: all 200ms ease-in-out;
      font-size: 24px;
      font-weight: 700;
      line-height: 100%;
      color: colors.$dark-gray-bravo;
    }
    .investment-card-sub {
      transition: all 200ms ease-in-out;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      color: colors.$dark-gray-alpha;
    }
  }
}

.investment-h-container {
  display: flex;
  flex-direction: row;
  .investment-h-image {
    height: 100%;
    img {
      width: 92px;
      height: 92px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .investment-h-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-left: 32px;
  }
  .investment-h-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    word-wrap: break-word;
    color: colors.$dark-gray-alpha;
    font-weight: 400;
    font-size: 16px;
    max-width: 378px;
    line-height: 150%;
    @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
      max-width: none;
    }
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: fit-content;
    .investment-h-image {
      padding: 12px;
      margin: 0;
    }
    .investment-h-content {
      padding: 12px;
      align-items: center;
      margin-left: 0px;
    }
  }
}

.investment-row-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    flex-direction: column;
    & > .column {
      flex-direction: row;
      margin-bottom: 16px;
    }
  }
}

.investment-status-label {
  margin-right: 32px;
  margin-top: 20px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: right;
}

.investment-status {
  display: flex;
  margin-right: 32px;
  margin-top: 12px;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;
  gap: 10px;
  color: colors.$white;
  background: colors.$golden;
  border-radius: 6px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
}

.error-status {
  background: colors.$blue-charlie;
}

.investment-doc-container {
  margin-left: 124px;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    margin-left: 0px;
  }
}

.assetclass-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: breakpoints.$breakpoint-mobile) {
    & h4 {
      font-size: 18px;
    }
  }
  .investment-status {
    margin-top: 0px;
    margin-left: 16px;
  }
}

.investment-doc-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 28px;

  a {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: colors.$dark-gray-alpha;
    text-decoration: none;
    width: fit-content;
    & svg {
      margin-right: 4px;
      & path {
        stroke: colors.$dark-gray-alpha;
      }
    }

    &:hover {
      color: colors.$blue-alpha;
      text-decoration: none;
      & svg path {
        stroke: colors.$blue-alpha;
      }
    }
  }
}

.investment-go-to-proj {
  width: max-content;
  padding: 0px 16px;
  margin-top: 32px;
}

.completed-basic-stepper-item::before {
  border-bottom: 2px solid colors.$blue-alpha;
}

.active-basic-stepper-item::before {
  border-bottom: 2px solid colors.$black;
}

.investment-card-stepper {
  width: 55%;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: 100%;
    & .basic-step-name {
      width: 180px;
    }
  }
  & .basic-stepper-wrapper {
    margin-top: 0px;
    border: none;
    padding: 24px 0px;
    border-radius: 0px;

    & .basic-stepper-item .basic-step-counter {
      margin-bottom: 24px;
    }
    & .basic-step-name {
      color: colors.$dark-gray-bravo;
    }
    & .basic-step-counter.completed {
      background: colors.$blue-alpha;
    }
  }
}

@media (max-width: breakpoints.$breakpoint-mobile) {
  .input-modal {
    width: 280px;

    & .styled-table {
      min-width: 200px;
    }
    .styled-table th,
    .styled-table td {
      min-width: 80px;
    }
  }
}
