@use '../../global-styles/colors';
@use '../../global-styles/mixins';
@use '../../global-styles/breakpoints';

$total-investments-bg-color: #ebf4ff;
$total-returns-bg-color: #e6faf0;
$unrealized-gains-bg-color: #ffebec;
$realized-gains-bg-color: #ffefeb;

$profit-color: #62a383;
$loss-color: #fa6470;

.dashboard-top-section-wrapper {
  & > h2 {
    letter-spacing: -0.02em;
  }
}

.dashboard-top-section {
  &-container-new {
    background: colors.$light-gray-foxtrot;
    border: 1px solid #e2e2e2;
    color: #2c3941;
    border-radius: 8px;
    padding: 30px 20px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    &-sections {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
        flex-direction: column;
      }
    }
  }

  &-left-new {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
      text-align: center;
      width: auto;
      overflow-wrap: break-word;
    }
    & > .basic-anchor {
      font-size: 14px;
    }
    & > .thin {
      font-size: 14px;
    }
    & > .investment-container {
      margin-top: 5px;
      margin-bottom: 8px;
    }
    & .investment-limit {
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 1px;
      font-weight: 700;
      text-transform: none;
    }

    & .investment-limit-values {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #2c3941;
    }
  }
  &-right-new {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    width: 40%;
    column-gap: 110px;
    margin-top: 0px;
    @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
      width: auto;
      align-items: center;
      margin-top: 40px;
    }
    &-status-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > a {
        font-size: 14px;
      }
      @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
        flex-direction: row;
        column-gap: 14px;
        align-items: center;
        justify-content: center;
        & > span {
          margin-top: 0;
        }
        & > a {
          margin-top: 0;
        }
      }
    }
    &-content-container {
      margin-top: 12px;
    }
    & span {
      font-size: 14px;
    }
    & p {
      color: #545a5e;
    }
  }
}

.specific-investment-info-section {
  &-container {
    border-bottom: 1px solid #e2e2e2;
    color: #2c3941;
    padding-bottom: 60px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: start;

    &-heading {
      color: colors.$blue-charlie;
    }

    &-body {
      display: flex;
      align-items: end;
      @media (max-width: breakpoints.$breakpoint-large-screen) {
        flex-direction: column;
        align-items: start;
      }
    }

    &-stats {
      margin-left: auto;
      display: flex;
      gap: 30px;
      @media (max-width: breakpoints.$breakpoint-large-screen) {
        margin-left: 0;
        margin-top: 20px;
      }
      @media (max-width: breakpoints.$breakpoint-tablet) {
        width: 100%;
        flex-direction: column;
      }
    }

    & .asset-class-tag {
      height: 100%;
    }

    & .loading-text {
      font-size: 20px;
    }
  }
}

.kyc-badge {
  margin-top: 0;
  margin-left: 20px;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  &-NOT_STARTED {
    @extend .kyc-badge;
    background-color: lighten(colors.$orange-alpha, 45%);
  }
  &-INITIATED {
    @extend .kyc-badge;
    background-color: lighten(colors.$orange-alpha, 45%);
  }
  &-APPROVED {
    @extend .kyc-badge;
    background-color: lighten(colors.$success, 45%);
  }
  &-PENDING {
    @extend .kyc-badge;
    background-color: lighten(colors.$orange-alpha, 45%);
  }
  &-SENT_FOR_APPROVAL {
    @extend .kyc-badge;
    background-color: lighten(colors.$orange-alpha, 45%);
  }
  &-REJECTED {
    @extend .kyc-badge;
    background-color: lighten(colors.$error, 45%);
  }
  &-RESUBMIT {
    @extend .kyc-badge;
    background-color: lighten(colors.$error, 45%);
  }
}

.investment-details-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  &-card {
    &-total-investments {
      @extend .card;
    }
    &-total-returns {
      @extend .card;
    }
    &-realized-gains {
      @extend .card;
    }
    &-unrealized-gains {
      @extend .card;
    }
    &-distribution-graph {
      @extend .card;

      &-2-col {
        grid-column: 1 / span 2;
        @media (max-width: breakpoints.$breakpoint-large) {
          grid-column: span 4 !important;
        }
      }
      &-3-col {
        grid-column: 1 / span 3;
        @media (max-width: breakpoints.$breakpoint-large) {
          grid-column: span 4 !important;
        }
      }
    }
    &-investment-distribution {
      @extend .card;
      grid-column: 3 / span 2;
      @media (max-width: breakpoints.$breakpoint-large) {
        grid-column: span 4 !important;
      }
    }
    &-withdrawal {
      @extend .card;
      grid-column: 4 / span 1;
      @media (max-width: breakpoints.$breakpoint-large) {
        grid-column: span 4 !important;
      }
    }
  }
}

.card {
  @media (max-width: breakpoints.$breakpoint-large) {
    grid-column: span 2;
  }
  @media (max-width: breakpoints.$breakpoint-large-screen) {
    grid-column: span 4;
  }
}

.investment-details-card {
  width: auto;
  padding: 30px 20px;
  display: flex;
  box-sizing: border-box;
  height: 100%;
  @media (max-width: breakpoints.$breakpoint-large) {
    max-width: 100%;
    justify-content: center;
  }

  &-container {
    display: flex;
    @media (max-width: breakpoints.$breakpoint-large) {
      width: 220px;
    }
  }
  &-value {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 4px;

    color: colors.$dark-gray-alpha;
  }
  &-text {
    font-weight: 400;
    font-size: 14px;
    margin-top: 4px;
    color: colors.$dark-gray-alpha;
  }
  &-icon-circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-total-investments {
      @extend .investment-details-card-icon-circle;
      background-color: $total-investments-bg-color;
    }
    &-total-returns {
      @extend .investment-details-card-icon-circle;
      background-color: $total-returns-bg-color;
    }
    &-realized-gains {
      @extend .investment-details-card-icon-circle;
      background-color: $realized-gains-bg-color;
    }
    &-unrealized-gains {
      @extend .investment-details-card-icon-circle;
      background-color: $unrealized-gains-bg-color;
    }
  }
  &-text-content {
    margin-left: 24px;
  }
}

.info-card {
  min-width: 100%;
  height: 100%;

  &-heading {
    padding: 20px;
    border-bottom: 1px solid colors.$light-gray-alpha;
  }
  &-body {
    padding: 20px;

    & .support-link {
      color: colors.$blue-alpha;
    }
  }
  &-footer {
    padding: 20px;
    padding-top: 0px;
  }

  &-withdrawal {
    height: 100%;
    @media (max-width: breakpoints.$breakpoint-large) {
      min-width: 100%;
    }

    & .payment-warning-container {
      @media (max-width: breakpoints.$breakpoint-large) {
        min-width: 95%;
      }
    }
  }
}

.opportunity-list-card {
  @extend .info-card;
  margin-top: 30px;

  background: colors.$light-gray-foxtrot;

  @media (max-width: breakpoints.$breakpoint-large) {
    min-height: 420px;
    width: 100%;
  }
  &-heading {
    @extend .info-card-heading;
  }
  &-body {
    @extend .info-card-body;
    display: flex;
    gap: 30px;
    overflow-x: scroll;
    height: 315px;
  }
}

.investment-graph-card {
  &-switcher-container {
    margin-bottom: 15px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & p {
      text-align: center;
    }
    & button {
      border: 1px solid colors.$light-gray-alpha;
      border-radius: 8px;
      margin: auto 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      cursor: pointer;

      &:first-child {
        align-self: flex-start;
      }
      &:last-child {
        align-self: flex-end;
      }

      &:disabled {
        cursor: not-allowed;
        background: colors.$white;
        border-color: colors.$white;

        & path {
          fill: colors.$light-gray-alpha;
        }
      }
    }
  }

  &-switcher-title {
    word-break: break-all;
    &:hover {
      text-decoration: underline;
    }
  }
}

.line-chart-container {
  &.recharts-hover {
    & .recharts-wrapper {
      &:hover {
        cursor: pointer !important;
      }

      & .recharts-legend-item-text {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.pie-chart {
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: 100% !important;
  }

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
      flex-direction: column;
    }
  }

  &-legend {
    margin-left: 70px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-column-gap: 40px;

    @media (max-width: breakpoints.$breakpoint-large) {
      margin-left: 0px;
      grid-column-gap: 80px;
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      &-marker {
        width: 4px;
        height: 80%;
        margin-right: 10px;
      }
      &-text {
        display: flex;
        flex-direction: column;
        align-items: start;
        &-value {
          font-weight: 700;
          font-size: 16px;
          color: colors.$dark-gray-bravo;
        }
        &-asset-name {
          font-weight: 400;
          font-size: 14px;
          color: colors.$dark-gray-alpha;
        }
      }
    }
  }
}

.opportunity-card {
  &-container {
    min-height: 260px;
    min-width: 600px;
    background: white;
  }
  &-header {
    padding: 20px;
    border-bottom: 1px solid colors.$light-gray-alpha;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;

    &-opportunity-name-col {
      width: fit-content;
      text-wrap: wrap;
      & > h6 {
        font-size: 14px;
      }
    }

    &-opportunity-status {
      display: flex;
      align-items: center;
      margin-top: 8px;

      &-container {
        width: fit-content;
        & .opportunity-card-status {
          width: auto;
          text-align: center;
        }
      }

      &-label {
        font-size: 13px;
        font-weight: 400;
      }
      &-chip {
        margin-left: 8px;
        margin-top: 0px !important;
      }
    }

    &-opportunity-stats-col {
      display: flex;
      gap: 20px;
      justify-content: end;
    }
  }

  &-body {
    & .basic-stepper-wrapper {
      border: 0;
      margin: 0;
      padding: 25px 0;

      & .basic-step-name {
        font-size: 14px !important;
      }
      & .basic-stepper-status {
        font-size: 12px !important;
      }
    }
  }

  &-footer {
    padding: 20px;
    border-top: 1px solid colors.$light-gray-alpha;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    &-transaction-col {
      display: flex;
      font-size: 14px;
      &-text {
        font-weight: 400;
      }
      &-value {
        font-weight: 700;
      }
    }

    &-investment-amount-col {
      display: flex;
      font-size: 14px;
      &-text {
        font-weight: 400;
      }
      &-value {
        font-weight: 700;
      }
    }
  }

  &-status {
    width: fit-content;
    margin-top: 12px;
    padding: 6px 12px;
    color: colors.$white;
    background: colors.$golden;
    border-radius: 6px;
    font-weight: 400;
    font-size: 12px;
  }
}

.opportunity-card-investment-stats-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  width: 80px;
  height: 50px;
  .opportunity-card-investment-card-main {
    transition: all 200ms ease-in-out;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    color: colors.$dark-gray-bravo;
  }
  .opportunity-card-investment-card-sub {
    transition: all 200ms ease-in-out;
    font-size: 11px;
    font-weight: 400;
    line-height: 120%;
    color: colors.$dark-gray-alpha;
  }
}

.dashboard-specific-investment-investment-stats-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  width: 140px;
  height: 90px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-around;
  }
  .dashboard-specific-investment-investment-card-main {
    transition: all 200ms ease-in-out;
    font-size: 24px;
    font-weight: 700;
    line-height: 100%;
    color: colors.$dark-gray-bravo;
  }
  .dashboard-specific-investment-investment-card-sub {
    transition: all 200ms ease-in-out;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    color: colors.$dark-gray-alpha;
  }
}

.no-project-found-container {
  height: 260px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  @media (max-width: breakpoints.$breakpoint-large) {
    & > p {
      text-align: center;
    }
  }
  @media (max-width: breakpoints.$breakpoint-mobile) {
    flex-direction: row;
  }
  & > span {
    font-weight: 800;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: #2c3941;
    margin-top: 24px;
    margin-bottom: 12px;
  }
}

.transactions-card {
  @extend .info-card;
  margin-top: 30px;

  @media (max-width: breakpoints.$breakpoint-large) {
    min-height: 420px;
    width: 100%;
  }
  &-heading {
    @extend .info-card-heading;
  }
  &-body {
    @extend .info-card-body;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-x: scroll;

    &-search-input {
      position: relative;
      border-radius: 8px;
      width: 460px;
      height: 54px;
      margin: 8px 0px;

      @media (max-width: breakpoints.$breakpoint-large) {
        width: 100%;
      }

      & input {
        padding: 17px 40px;
        font-weight: 400;
        font-size: 16px;
      }

      &-box {
        padding: 17px 40px;
        font-weight: 400;
        font-size: 16px;
        @include mixins.font();
        padding: 17px 16px;
        border: 1px solid colors.$light-gray-delta;
        border-radius: 8px;
        background-color: colors.$white;
        width: 100%;
        box-sizing: border-box;
        font-style: normal;
        font-size: 14px;
        line-height: 120%;
        color: colors.$light-gray-echo;
        &:hover {
          border: 1px solid colors.$dark-gray-alpha;
        }

        &:focus-visible {
          outline: none !important;
          color: colors.$dark-gray-bravo;
          border: 1px solid colors.$dark-gray-alpha;
        }
      }

      &-icon {
        position: absolute;
        margin-top: 18px;
        left: 15px;
      }
    }

    &-table {
      min-width: 100%;
      border-radius: 18px;

      @media (max-width: breakpoints.$breakpoint-large) {
        width: 1200px;
      }

      &-container {
        overflow: scroll;
      }

      &-hover {
        cursor: pointer;
        transition: all 200ms ease-in-out;
        &:hover {
          background-color: colors.$light-gray-hotel !important;
        }
      }

      th {
        height: 60px;
        background-color: #eef0f2;
        color: #666666;
        text-align: center;
        font-weight: 700;
        font-size: 12px;

        &:first-child {
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
        }
      }
      tbody {
        text-align: center;
      }

      tr {
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 8px;
            }
            &:last-child {
              border-bottom-right-radius: 8px;
            }
          }
        }

        &:nth-child(odd) {
          background-color: #f9fcff;
        }
        &:nth-child(even) {
          background-color: #ebf5ff;
        }
      }

      &-cell {
        text-align: center;
        color: colors.$dark-gray-bravo;
        font-weight: 700;
        font-size: 14px;

        &-left-20 {
          text-align: left !important;
          padding-left: 20px;
        }

        &-opportunity-title {
          font-size: 14px;
          font-weight: 700;
          color: colors.$dark-gray-bravo;
        }

        &-asset-class {
          font-size: 12px;
          font-weight: 400;
          color: colors.$dark-gray-bravo;
        }
      }

      td {
        height: 60px;
      }
    }
  }
  &-footer {
    @extend .info-card-footer;

    & .projects-pagination-wrapper {
      @media (max-width: breakpoints.$breakpoint-large) {
        display: flex;
        flex-direction: column;

        &-info {
          margin-bottom: 20px;
          align-self: self-start;
        }
      }
    }
    & .items-container {
      gap: 12px;

      & .page-item {
        &:not(:first-child):not(:nth-child(2)):not(:nth-last-child(1)):not(:nth-last-child(2)):not(
            .page-item-dots
          ) {
          border: 1px solid colors.$light-gray-alpha;
          border-radius: 8px;
        }

        &.page-item-dots:hover {
          cursor: default;
          background-color: transparent;
        }
      }
    }
  }
}

.status-chip {
  margin-top: 8px;
  border: 1px solid #ccbd66;
  border-radius: 4px;
  padding: 2px 4px;
  width: fit-content;
  color: #ccab29;
  font-size: 12px;
  font-weight: 700;
  background-color: #fffbeb;

  &-error {
    @extend .status-chip;
    background: lighten(colors.$red-alpha, 5%);
    border-color: lighten(colors.$red-alpha, 5%);
    color: white;
  }
}

.text-color-profit {
  color: $profit-color;
}
.text-color-loss {
  color: $loss-color;
}

.back-button-container {
  align-items: center;
  .back-button {
    font-weight: 700;
    color: colors.$blue-charlie;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    visibility: hidden;
  }
}

.margin-y-auto {
  margin: auto 0;
}

.margin-x-auto {
  margin: 0 auto;
}

.cursor-pointer {
  cursor: pointer !important;
}
