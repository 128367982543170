@use '../../../global-styles/colors';

.transaction-checklist-modal {
  position: absolute;
  background: colors.$white;

  max-width: 750px;
  top: 100px;
  bottom: 10px;
}

.modal-card-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.checklist-ordered-list {
  list-style-type: circle;
  margin-left: 20px;
}
