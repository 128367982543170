@use './global-styles/colors';
@use './global-styles/mixins';
@use './global-styles/breakpoints';

.App {
  background-color: white;

  overflow: clip;

  @include mixins.font(700);
  padding: 36px 70px 48px 70px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    padding: 32px 25px;
  }
}

.verify-email-top-warning-banner {
  @include mixins.font(700);
  font-size: 16px;
  line-height: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  background-color: colors.$orange-bravo;
  color: colors.$orange-alpha;
  & > span {
    margin: 0px 12px;
  }
}
