@use '../../global-styles/colors';
@use '../../global-styles/mixins';

// Width
.width-100percent {
  width: 100%;
}
.width-90percent {
  width: 90%;
}
.width-80percent {
  width: 80%;
}

.width-24px {
  width: 24px;
}

// Height
.height-50percent {
  height: 50%;
}
.height-100percent {
  height: 100%;
}

.height-24px {
  height: 24px;
}
.height-96px {
  height: 96px;
}
.height-108px {
  height: px;
}

.height-min-150px {
  min-height: 150px;
}

// Aspect ratio
.aspect-ratio1 {
  aspect-ratio: 1;
}

// Text
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-justify {
  text-align: justify !important;
}

// Margin
.ml-12px {
  margin-left: 12px;
}
.ml-14px {
  margin-left: 14px;
}
.ml-24px {
  margin-left: 24px;
}

.mt-12px {
  margin-top: 12px;
}
.mt-14px {
  margin-top: 14px;
}
.mt-18px {
  margin-top: 18px;
}
.mt-24px {
  margin-top: 24px;
}
.mt-32px {
  margin-top: 32px;
}
.mt-48px {
  margin-top: 48px;
}
.mt-64px {
  margin-top: 64px;
}

// Border
.border-radius-15px {
  border-radius: 15px;
}
.border-radius-10px {
  border-radius: 10px;
}

.border-radius-50percent {
  border-radius: 50%;
}

// Heading
.headings-blue-charlie {
  color: colors.$blue-charlie;

  line-height: 120%;
}

// Font
.font-16px {
  font-size: 16px;
}

// Flex
.flex {
  display: flex;
}

.flex-direction-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-justify-center {
  justify-content: center;
}
.flex-align-center {
  align-items: center;
}
.flex-item-2col {
  flex: 1 1 50%;
  max-width: 50%;
}
.flex-item-3col {
  flex: 1 1 calc(100% / 3);
  max-width: calc(100% / 3);
}
.flex-item-4col {
  flex: 1 1 25%;
  max-width: 25%;
}

.flex-1 {
  flex: 1 !important;
}
.flex-1p5 {
  flex: 1.5 !important;
}
.flex-5 {
  flex: 5 !important;
}

// Padding
.padding-24px {
  padding: 24px;
}

// Gap
.gap-10px {
  gap: 10px;
}
.gap-40px {
  gap: 40px;
}

// List
.connected-list {
  list-style: none;
  position: relative;

  & li {
    position: relative;
    padding: 10px 0 10px 25px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 17px;
      width: 10px;
      height: 10px;
      background-color: colors.$blue-charlie;
      border-radius: 50%;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      left: 3px;
      top: 20px;
      height: 100%;
      width: 4px;
      background-color: #bddbfd;
      z-index: 1;
    }

    &:last-child::after {
      display: none;
    }
  }
}

// Containers
.card {
  background-color: #caa5661c;
  overflow: hidden;

  flex: 1;
  border-radius: 10px;

  &-padding {
    padding: 18px;
  }

  &-image {
    background-color: transparent;
  }
}

.investment-reason {
  background-color: #ddf6ff;
  padding: 32px;

  &-img {
    display: flex;
    justify-content: center;
  }
}

// Colors
.background-white {
  background-color: white;
}
.text-blue {
  color: colors.$blue-charlie;
}
.text-darkgray {
  color: colors.$dark-gray-alpha;
}
.text-darkgray-bravo {
  color: colors.$dark-gray-bravo;
}

// General
.basic {
  @include mixins.font(400);
  color: colors.$dark-gray-alpha;
  text-align: left;

  font-size: 14px;
}
