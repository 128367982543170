@use '../../global-styles/breakpoints';
@use '../../global-styles/colors';
@use '../../global-styles/mixins';

.how-it-works-wrapper,
.trusted-by-wrapper,
.just-launched-wrapper,
.success-stories-wrapper {
  width: 100%;
}
.just-launched-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin-top: 120px;
  height: 100%;

  & > h2 {
    margin-bottom: 56px;
    text-align: center;
    letter-spacing: -0.02em;
  }
}
.trusted-by-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  & > h2 {
    text-align: center;
    letter-spacing: -0.02em;
  }
}
.trusted-by-img-container {
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  & img {
    height: fit-content;
  }
}
.success-stories-wrapper {
  & > div {
    & > h2 {
      margin-bottom: 56px;
      text-align: center;
      letter-spacing: -0.02em;
    }
  }
}
.success-stories-muted-wrapper {
  position: absolute;
  width: 100%;
  height: 150px;
  background: #f9f9f9bd;
  bottom: 0;
  right: 0;
  left: 0;
}
.success-stories-expand-button {
  width: 30px;
  height: 30px;
  border-radius: 6px;
}

.homepage-cover-wrapper {
  position: relative;
  display: flex;

  margin-bottom: 50px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
    align-items: center;
  }
}

.homepage-link {
  color: colors.$blue-charlie;
}

.homepage-learn-more-icon {
  display: inline;
  position: absolute;
}

.homepage-card-background {
  position: absolute;
  background-image: url(https://gbc-credibila-assets.s3.amazonaws.com/images/home-cover-bg.png);
  background-repeat: round;

  width: calc(100% + 72 * 2px);
  height: calc(100% + 100px);
  top: 50px;
  left: -72px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    background-repeat: no-repeat;
  }
}

.homepage-cover {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  max-width: 60%;
  left: 20%;
  gap: 20px;
  margin-bottom: 100px;

  &-background {
    position: absolute;
    background: linear-gradient(90deg, #fff, #ecf7ef 60%, #a9c8ec);

    height: calc(100% + 100px);
    left: -72px;
    top: -36px;
    width: calc(100% + 72 * 2px);
  }

  &-card {
    position: relative;
    display: flex;
    align-items: flex-start;

    flex: 0 1 calc(50% - 20px);

    img {
      width: 24px;
    }

    div {
      position: relative;

      max-width: calc(100% - 40px);
      left: 20px;

      span {
        font-weight: lighter;
      }
    }

    @media (max-width: breakpoints.$breakpoint-tablet) {
      flex: 0 1 100%;
    }
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    left: 5%;
    max-width: 90%;
  }
}

.homepage-header {
  margin-top: 34px;
  margin-bottom: 40px;
  z-index: 10;

  & > h2 {
    letter-spacing: -0.02em;
  }
  & > p {
    text-align: center;
    color: colors.$dark-gray-alpha;
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 100%;

    text-align: center;
  }
}
.homepage-signup-container {
  position: relative;
  text-align: center;

  gap: 14px;
  z-index: 10;

  span {
    display: flex;
    align-items: center;
    flex: none;
    justify-content: center;

    font-size: 20px;
    line-height: 1.5;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 100%;
    flex-direction: column;
    row-gap: 12px;
    input {
      width: 100%;
    }
  }
}

.homepage-signup-form {
  gap: 14px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
    gap: 14px;
  }
}

.cta-container {
  .cta-banner-container {
    justify-content: center;
    flex-direction: column;
    div {
      justify-content: center;
      align-items: center;
    }
  }
}

.how-it-works-wrapper {
  position: relative;
  overflow: hidden;

  margin-top: 128px;

  &-heading {
    letter-spacing: -0.02em;
  }

  &-subheading {
    margin-top: 20px;
    text-align: justify;

    @media (max-width: breakpoints.$breakpoint-large) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  @media (max-width: breakpoints.$breakpoint-large) {
    margin-top: 56px;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    text-align: center;
  }
}

.how-it-works-line-eraser {
  position: absolute;
  background: white;

  top: 280px;
  width: 100%;
  height: 30px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    display: none;
  }
}

.how-it-works-card {
  max-width: 400px;
  height: auto;
  background: colors.$white;
  padding: 32px 0;

  & img {
    height: 113px;
    width: 113px;
  }
  &-icon {
    margin-bottom: 25px;
  }

  &-heading {
    font-size: 24px;
    line-height: 120%;
    text-align: left;
    margin-bottom: 24px;

    @media (max-width: breakpoints.$breakpoint-tablet) {
      text-align: center;
    }
  }
  &-subheading {
    text-align: left;
    color: #545a5e;
    margin-bottom: 40px;

    @media (max-width: breakpoints.$breakpoint-tablet) {
      text-align: center;
    }
  }
  &-property {
    font-size: 14px;
    line-height: 140%;
    color: #545a5e;
    &-value {
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      text-align: right;
      color: #f53b4d;
    }
    &-container {
      margin-bottom: 17px;
    }
  }
  &:nth-child(2) {
    margin-left: 16px;
    margin-right: 16px;
    @media (max-width: breakpoints.$breakpoint-tablet) {
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }
}
.how-it-works-container {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
  }
}
.how-it-works-cards-container {
  margin-top: 20px;
  justify-content: space-between;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.how-it-works-line {
  position: absolute;

  top: 295px;
  margin: 0 !important;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    display: none;
  }
}

.how-it-works-counter {
  position: relative;
  text-align: center;
  background-color: white;

  width: 25px;
  z-index: 10;
  left: -4px;

  &-last {
    text-align: left;

    width: 120%;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    display: none;
  }
}

.just-launched-card-container {
  margin-top: 20px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.benefit-container-wrapper {
  &-heading {
    display: flex;
    justify-content: space-between;
    background: white;

    & > h2 {
      letter-spacing: -0.02em;
      margin-bottom: 57px;
    }
    & > button {
      width: max-content;
      padding: 17px 32px;
    }
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
    justify-content: center;
  }
}
.benefit-cards {
  width: 333px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex: 0 0 auto;

  &-heading {
    font-size: 24px;
  }
  &-subheading {
    color: #545a5e;
  }
  &-container {
    overflow-x: auto;
    gap: 32px;
  }
  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88px;
    height: 88px;
    border: 1px solid colors.$light-gray-alpha;
    border-radius: 8px;
    background: #f9f9f9;
  }
}
.breadcrumbs-arrow {
  margin-left: 8px;
  margin-right: 8px;
}
.success-stories-container {
  background: colors.$light-gray-foxtrot;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  padding: 32px;
  height: 600px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    padding: 24px;
  }
  &-overflow {
    overflow: auto;
  }
  & .story-card-gap {
    gap: 24px;
  }
}

.success-story-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 32px 32px 32px;
  width: 328px;
  background: colors.$white;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 260px;
    padding: 40px 24px;
  }
  & .card-sub-heading {
    @include mixins.font(400);
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    margin-top: 32px;
    color: colors.$dark-gray-alpha;
  }
  & .user-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 24px;
    width: 100%;
    margin: 42px 0px;
    & > img {
      height: 60px;
      width: 60px;
    }
    & .user-title {
      font-size: 24px;
    }
    & .user-designation {
      @include mixins.font(700);
      font-size: 14px;
      line-height: 120%;
      color: colors.$dark-gray-alpha;
    }
  }
}
.create-meaningful-wrapper {
  & > div {
    & .cta-banner-container {
      max-height: unset;
      padding-top: 60px;
      padding-bottom: 60px;
      & > div {
        & > h4 {
          text-align: center;
          letter-spacing: -0.02em;
          font-size: 40px;
          line-height: 120%;
        }
        & > p {
          text-align: center;
        }
      }
      & > button {
        margin-top: 40px;
      }
    }
  }
}

.gray-background-image {
  background: url('../../../public/assets/joel-filipe-PFIeJh17SZo-unsplash\ 1\ \(1\).png') no-repeat;
  background-size: cover;
  border-radius: 16px;
  height: 324px;
  margin-top: 30px;
  margin-left: -60px;
  margin-right: -60px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.why-credibila-wrapper {
  position: relative;
  background-color: #f7f7f7;

  left: -72px;
  padding: 50px 72px;
  width: 100%;
}

.why-credibila-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  background-color: white;
  width: auto !important;

  border-radius: 10px;
  padding: 50px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
  }
}

.why-credibila-content {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    text-align: center;

    p {
      text-align: center;
    }
  }
}

.why-credibila-img {
  border-radius: 10px;
}

.homepage-start-investing {
  &-wrapper {
    position: relative;
    background-color: #ecf7ef;

    left: -72px;
    padding: 20px 72px;
    width: 100%;
  }

  &-container {
    position: relative;
    flex-direction: column;
    align-items: center;
    text-align: center;

    z-index: 1;
    gap: 14px;

    span {
      display: flex;
      align-items: center;
      flex: none;
      justify-content: center;

      font-size: 20px;
      line-height: 1.5;
    }

    @media (max-width: breakpoints.$breakpoint-tablet) {
      width: 100%;
      flex-direction: column;
      row-gap: 12px;

      input {
        width: 100%;
      }
    }
  }
}

.homepage-signup-button {
  width: fit-content;
  min-width: fit-content;

  height: 52px;
  padding: 20px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    height: 52px;
    width: auto;
    margin: 0px;
  }
}

.learn-more-wrapper {
  position: relative;
  background-color: #f7f7f7;

  left: -72px;
  padding: 50px 72px;
  width: 100%;
}

.learn-more-img-container {
  text-align: center;

  width: 100%;

  img {
    max-width: 100%;
  }
}

.learn-more-content-container {
  width: 100%;
}

.learn-more-accordion {
  width: 24px;
  height: 24px;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 12px;
  text-align: center;
}

.learn-more-accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid colors.$light-gray-alpha;
  background: #ffffff;
  height: 50px;
  color: colors.$dark-gray-alpha;
  padding: 16px;
  @include mixins.font(700);
  font-size: 16px;
  line-height: 130%;
}

.learn-more-accordion-icon {
  width: 24px;
  height: 24px;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 12px;
  text-align: center;
}

.learn-more-collapse {
  transform: rotate(180deg);
}

.trust-container {
  margin: 96px 0;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    text-align: center;
  }
}

.trust-content-wrapper {
  gap: 100px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;

    gap: 0;
  }
}

.trust-content {
  width: 100%;

  &-img {
    max-width: 100%;
  }
}
