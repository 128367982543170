@use '../../../global-styles/colors';
@use '../../../global-styles/breakpoints';

.cta-banner-container {
  min-height: 224px;
  max-height: 320px;
  width: -webkit-fill-available;
  background-color: colors.$light-gray-bravo;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 124px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    button {
      margin-top: 32px;
    }
  }
}
