@use '../../../global-styles/colors';

.investment-essentials-modal {
  position: absolute;
  background: colors.$white;

  max-width: 550px;
  top: 100px;
  bottom: 10px;
}

.modal-card-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}
