@use '../../../global-styles/colors';

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: red;
}

.form-control {
  & .MuiOutlinedInput-root {
    // Color of the Datepicker box
    & fieldset {
      border-color: colors.$light-gray-delta;
      border-radius: 8px;
    }
    &:hover fieldset {
      border-color: colors.$dark-gray-alpha;
    }
    &.Mui-focused {
      & fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid colors.$dark-gray-alpha;
      }
    }

    // Color of the Datepicker value
    & .MuiInputBase-input {
      color: colors.$light-gray-echo;
    }
    & .MuiInputBase-input:focus {
      color: colors.$dark-gray-bravo;
    }
  }
}
