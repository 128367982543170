@use '../../../global-styles/mixins';
@use '../../../global-styles/colors';

.navigation-wrapper {
  position: sticky;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
}

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  color: colors.$black;
  border-bottom: 1px solid colors.$light-gray-alpha;
  padding: 0 72px;

  @media screen and (max-width: 992px) {
    padding-left: 36px;

    background-color: colors.$white;
    backdrop-filter: none;
  }
}

.brand-name {
  text-decoration: none;
  color: colors.$black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  flex: 0.3;
  justify-content: end;
  display: flex;
}
.loggedIn {
  margin-right: 22px;
  @media screen and (max-width: 992px) {
    margin-right: 0px;
  }
}
.navigation-menu .nav-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: top 0.3s ease;

  padding: 0;

  @media screen and (max-width: 992px) {
    background-color: colors.$white;
  }
}

.nav-link {
  text-align: center;
}

.navigation-menu .nav-item {
  list-style-type: none;
  margin: 0 6px;
  width: max-content;

  button {
    transition: height 0.3s ease;
  }
}

.navigation-menu .nav-item a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: colors.$white;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  right: 20px;
  display: none;
}

@media screen and (max-width: 992px) {
  .nav-link-bold {
    @include mixins.font(600);
    font-size: 16px;
    line-height: 120%;
    color: colors.$dark-gray-alpha;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      cursor: pointer;
      color: colors.$blue-charlie;
    }
    &:active {
      text-decoration: none;
      cursor: pointer;
      color: colors.$dark-gray-alpha;
    }
  }
  .navbar-btn-groups {
    display: flex;
    flex-direction: column;
  }
  .hamburger {
    display: block;
  }
  .navigation-menu .nav-list {
    flex-direction: column;
    position: absolute;
    top: 86px;
    left: 0;
    width: 100%;
    height: 100vh;
    display: none;
  }

  .navigation-menu .nav-item {
    text-align: center;
    margin: 0;
    width: 100%;
  }

  .navigation-menu .nav-item a {
    color: colors.$dark-gray-alpha;
    font-size: 16px;
    line-height: 140%;
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu.expanded .nav-list {
    display: block;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 8px;
  }
  .navigation-menu.expanded .nav-item {
    border-bottom: 1px solid colors.$light-gray-alpha;
  }
  .navigation-menu.expanded {
    z-index: 10;
  }
}

.select {
  position: relative;
}

.selectWrapper {
  position: relative;
  margin-left: 30px;
}

.selectCustom {
  position: relative;
  width: max-content;
}

.selectCustom-trigger {
  @include mixins.font(400);
  position: relative;
  color: colors.$light-gray-echo;
  cursor: pointer;

  padding: 8px;
  font-size: 14px;
}

.expand-more-icon {
  transform: rotate(180deg);
}

.selectCustom-trigger:hover {
  border-color: colors.$light-gray-alpha;
}

.selectCustom-options {
  position: absolute;
  top: 3.6rem;
  left: 0;
  width: fit-content;
  @include mixins.font(400);
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 0.4rem;
  background-color: colors.$white;
  color: colors.$light-gray-echo;
  box-shadow: 0 0 4px colors.$light-gray-golf;
  z-index: 1;
  display: none;
}

.selectCustom-option-logout {
  color: colors.$error;
  position: relative;
  padding: 18px 16px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  &:hover {
    background-color: colors.$red-foxtrot;
    cursor: pointer;
  }
}

.selectCustom.isActive .selectCustom-options {
  display: block;
}

.selectCustom-option {
  position: relative;
  padding: 18px 16px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-bottom: 1px solid colors.$light-gray-alpha;
}

.selectCustom-option:hover {
  background-color: colors.$light-gray-alpha;
  cursor: pointer;
}

.selectCustom-option:not(:last-of-type)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0.8rem;
  width: calc(100% - 1.6rem);
  border-bottom: 1px solid colors.$light-gray-alpha;
}
.navbar-btn-padding {
  width: auto;
  min-width: 145px;

  padding: 16px;
}
