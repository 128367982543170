@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

select {
  @include mixins.font();
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='11' height='6' viewBox='0 0 11 6' fill='none'><path d='M9.7082 0.342762C10.0037 0.0472483 10.4828 0.0472512 10.7784 0.342768C11.0739 0.638285 11.0739 1.11741 10.7784 1.41292L6.41282 5.77837C6.1173 6.07388 5.63817 6.07388 5.34265 5.77836C5.04713 5.48284 5.04713 5.00372 5.34266 4.7082L9.7082 0.342762Z' fill='black'/><path d='M2.03847 0.331518C1.74295 0.0360047 1.26382 0.0360075 0.968305 0.331524C0.672788 0.627041 0.67279 1.10617 0.968311 1.40168L5.33385 5.76712C5.62937 6.06264 6.1085 6.06263 6.40402 5.76712C6.69954 5.4716 6.69953 4.99247 6.40401 4.69696L2.03847 0.331518Z' fill='black'/></svg>");
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding: 17px 24px 17px 16px;
  border: 1px solid colors.$light-gray-delta;
  border-radius: 8px;
  background-color: colors.$white;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 120%;
  color: colors.$light-gray-echo;
  margin: 8px 0px;
  &:hover {
    border: 1px solid colors.$dark-gray-alpha;
  }
  &:focus-visible {
    outline: none !important;
    color: colors.$dark-gray-bravo;
    border: 1px solid colors.$dark-gray-alpha;
  }
}
