@import url(https://themes.googleusercontent.com/fonts/css?kit=4ac_Wque7zU_ftkOZpcJzeQcljoOQfrD_BW4QUqVj8Lvk5vJX24UNpdrxCUvs7YG_kmCvy597kDopqFAXvpeaA);
ol.lst-kix_list_7-0 {
  list-style-type: none;
}
ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 0;
}
ol.lst-kix_list_13-4.start {
  counter-reset: lst-ctn-kix_list_13-4 0;
}
.lst-kix_list_13-0 > li {
  counter-increment: lst-ctn-kix_list_13-0;
}
ol.lst-kix_list_20-2.start {
  counter-reset: lst-ctn-kix_list_20-2 0;
}
ol.lst-kix_list_7-5 {
  list-style-type: none;
}
ol.lst-kix_list_7-6 {
  list-style-type: none;
}
ol.lst-kix_list_7-7 {
  list-style-type: none;
}
ol.lst-kix_list_7-8 {
  list-style-type: none;
}
ol.lst-kix_list_7-1 {
  list-style-type: none;
}
ol.lst-kix_list_7-2 {
  list-style-type: none;
}
ol.lst-kix_list_7-3 {
  list-style-type: none;
}
ol.lst-kix_list_7-4 {
  list-style-type: none;
}
ol.lst-kix_list_17-1.start {
  counter-reset: lst-ctn-kix_list_17-1 0;
}
.lst-kix_list_18-8 > li {
  counter-increment: lst-ctn-kix_list_18-8;
}
ol.lst-kix_list_8-8.start {
  counter-reset: lst-ctn-kix_list_8-8 0;
}
ol.lst-kix_list_10-4.start {
  counter-reset: lst-ctn-kix_list_10-4 0;
}
.lst-kix_list_7-2 > li {
  counter-increment: lst-ctn-kix_list_7-2;
}
.lst-kix_list_24-7 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_16-5 {
  list-style-type: none;
}
ol.lst-kix_list_16-6 {
  list-style-type: none;
}
.lst-kix_list_24-8 > li:before {
  content: '\0025aa   ';
}
ol.lst-kix_list_16-7 {
  list-style-type: none;
}
ol.lst-kix_list_16-8 {
  list-style-type: none;
}
ol.lst-kix_list_9-5.start {
  counter-reset: lst-ctn-kix_list_9-5 0;
}
ol.lst-kix_list_16-1 {
  list-style-type: none;
}
ol.lst-kix_list_16-2 {
  list-style-type: none;
}
ol.lst-kix_list_16-3 {
  list-style-type: none;
}
ol.lst-kix_list_16-4 {
  list-style-type: none;
}
.lst-kix_list_24-2 > li:before {
  content: '\0025aa   ';
}
ol.lst-kix_list_16-0 {
  list-style-type: none;
}
ol.lst-kix_list_20-7.start {
  counter-reset: lst-ctn-kix_list_20-7 0;
}
.lst-kix_list_24-3 > li:before {
  content: '\0025cf   ';
}
li.li-bullet-13:before {
  margin-left: -7.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 7.4pt;
}
.lst-kix_list_24-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_9-4 > li {
  counter-increment: lst-ctn-kix_list_9-4;
}
.lst-kix_list_24-5 > li:before {
  content: '\0025aa   ';
}
.lst-kix_list_24-6 > li:before {
  content: '\0025cf   ';
}
ol.lst-kix_list_16-4.start {
  counter-reset: lst-ctn-kix_list_16-4 0;
}
ol.lst-kix_list_14-1.start {
  counter-reset: lst-ctn-kix_list_14-1 0;
}
.lst-kix_list_23-6 > li:before {
  content: '\0025cf   ';
}
.lst-kix_list_6-5 > li {
  counter-increment: lst-ctn-kix_list_6-5;
}
li.li-bullet-43:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_23-3 > li:before {
  content: '\0025cf   ';
}
.lst-kix_list_23-7 > li:before {
  content: 'o  ';
}
.lst-kix_list_23-2 > li:before {
  content: '\0025aa   ';
}
li.li-bullet-32:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_23-0 > li:before {
  content: '-  ';
}
.lst-kix_list_23-8 > li:before {
  content: '\0025aa   ';
}
li.li-bullet-21:before {
  margin-left: -7.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 7.4pt;
}
.lst-kix_list_23-1 > li:before {
  content: 'o  ';
}
li.li-bullet-29:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_24-1 > li:before {
  content: 'o  ';
}
.lst-kix_list_24-0 > li:before {
  content: '\0025cf   ';
}
.lst-kix_list_23-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_23-5 > li:before {
  content: '\0025aa   ';
}
.lst-kix_list_22-2 > li:before {
  content: '\0025aa   ';
}
.lst-kix_list_22-6 > li:before {
  content: '\0025cf   ';
}
.lst-kix_list_8-6 > li {
  counter-increment: lst-ctn-kix_list_8-6;
}
.lst-kix_list_22-0 > li:before {
  content: '-  ';
}
.lst-kix_list_22-8 > li:before {
  content: '\0025aa   ';
}
ol.lst-kix_list_9-7 {
  list-style-type: none;
}
ol.lst-kix_list_9-8 {
  list-style-type: none;
}
ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}
ol.lst-kix_list_9-3 {
  list-style-type: none;
}
ol.lst-kix_list_9-4 {
  list-style-type: none;
}
ol.lst-kix_list_9-5 {
  list-style-type: none;
}
ol.lst-kix_list_9-6 {
  list-style-type: none;
}
ol.lst-kix_list_9-0 {
  list-style-type: none;
}
ol.lst-kix_list_9-1 {
  list-style-type: none;
}
ol.lst-kix_list_9-2 {
  list-style-type: none;
}
.lst-kix_list_22-4 > li:before {
  content: 'o  ';
}
li.li-bullet-24:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_25-5 > li:before {
  content: '\0025aa   ';
}
.lst-kix_list_25-7 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_18-3.start {
  counter-reset: lst-ctn-kix_list_18-3 0;
}
ol.lst-kix_list_18-7 {
  list-style-type: none;
}
ol.lst-kix_list_18-8 {
  list-style-type: none;
}
ol.lst-kix_list_18-3 {
  list-style-type: none;
}
ol.lst-kix_list_18-4 {
  list-style-type: none;
}
.lst-kix_list_6-4 > li {
  counter-increment: lst-ctn-kix_list_6-4;
}
ol.lst-kix_list_18-5 {
  list-style-type: none;
}
ol.lst-kix_list_18-6 {
  list-style-type: none;
}
ol.lst-kix_list_18-0 {
  list-style-type: none;
}
ol.lst-kix_list_18-1 {
  list-style-type: none;
}
.lst-kix_list_9-3 > li {
  counter-increment: lst-ctn-kix_list_9-3;
}
ol.lst-kix_list_18-2 {
  list-style-type: none;
}
ol.lst-kix_list_15-2.start {
  counter-reset: lst-ctn-kix_list_15-2 0;
}
ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}
ol.lst-kix_list_15-3.start {
  counter-reset: lst-ctn-kix_list_15-3 0;
}
ol.lst-kix_list_12-2.start {
  counter-reset: lst-ctn-kix_list_12-2 0;
}
ol.lst-kix_list_6-0.start {
  counter-reset: lst-ctn-kix_list_6-0 0;
}
.lst-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}
ol.lst-kix_list_19-0.start {
  counter-reset: lst-ctn-kix_list_19-0 0;
}
ol.lst-kix_list_3-0 {
  list-style-type: none;
}
.lst-kix_list_7-1 > li {
  counter-increment: lst-ctn-kix_list_7-1;
}
ul.lst-kix_list_5-7 {
  list-style-type: none;
}
ul.lst-kix_list_5-8 {
  list-style-type: none;
}
ul.lst-kix_list_5-5 {
  list-style-type: none;
}
ul.lst-kix_list_5-6 {
  list-style-type: none;
}
.lst-kix_list_21-8 > li:before {
  content: '\0025aa   ';
}
.lst-kix_list_16-0 > li {
  counter-increment: lst-ctn-kix_list_16-0;
}
.lst-kix_list_8-0 > li {
  counter-increment: lst-ctn-kix_list_8-0;
}
ul.lst-kix_list_5-0 {
  list-style-type: none;
}
.lst-kix_list_10-0 > li {
  counter-increment: lst-ctn-kix_list_10-0;
}
ol.lst-kix_list_18-2.start {
  counter-reset: lst-ctn-kix_list_18-2 0;
}
ul.lst-kix_list_5-3 {
  list-style-type: none;
}
ul.lst-kix_list_5-4 {
  list-style-type: none;
}
ul.lst-kix_list_5-1 {
  list-style-type: none;
}
ul.lst-kix_list_5-2 {
  list-style-type: none;
}
li.li-bullet-35:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_21-0 > li:before {
  content: '\0025cf   ';
}
li.li-bullet-46:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_13-1 > li {
  counter-increment: lst-ctn-kix_list_13-1;
}
.lst-kix_list_21-1 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_15-8.start {
  counter-reset: lst-ctn-kix_list_15-8 0;
}
.lst-kix_list_10-2 > li {
  counter-increment: lst-ctn-kix_list_10-2;
}
.lst-kix_list_21-5 > li:before {
  content: '\0025aa   ';
}
li.li-bullet-40:before {
  margin-left: -21.3pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.3pt;
}
.lst-kix_list_21-4 > li:before {
  content: 'o  ';
}
ul.lst-kix_list_23-0 {
  list-style-type: none;
}
ul.lst-kix_list_23-1 {
  list-style-type: none;
}
ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}
ul.lst-kix_list_23-2 {
  list-style-type: none;
}
ul.lst-kix_list_23-3 {
  list-style-type: none;
}
ul.lst-kix_list_23-4 {
  list-style-type: none;
}
ul.lst-kix_list_23-5 {
  list-style-type: none;
}
ul.lst-kix_list_23-6 {
  list-style-type: none;
}
ol.lst-kix_list_19-5.start {
  counter-reset: lst-ctn-kix_list_19-5 0;
}
ol.lst-kix_list_12-5 {
  list-style-type: none;
}
ol.lst-kix_list_12-6 {
  list-style-type: none;
}
ol.lst-kix_list_12-7 {
  list-style-type: none;
}
ol.lst-kix_list_12-8 {
  list-style-type: none;
}
ol.lst-kix_list_12-1 {
  list-style-type: none;
}
ol.lst-kix_list_12-2 {
  list-style-type: none;
}
ol.lst-kix_list_12-3 {
  list-style-type: none;
}
ol.lst-kix_list_12-4 {
  list-style-type: none;
}
ol.lst-kix_list_12-0 {
  list-style-type: none;
}
.lst-kix_list_25-1 > li:before {
  content: 'o  ';
}
ul.lst-kix_list_23-7 {
  list-style-type: none;
}
.lst-kix_list_25-0 > li:before {
  content: '-  ';
}
ul.lst-kix_list_23-8 {
  list-style-type: none;
}
ol.lst-kix_list_10-8.start {
  counter-reset: lst-ctn-kix_list_10-8 0;
}
li.li-bullet-18:before {
  margin-left: -8.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 8.4pt;
}
ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}
.lst-kix_list_16-7 > li {
  counter-increment: lst-ctn-kix_list_16-7;
}
ol.lst-kix_list_20-6.start {
  counter-reset: lst-ctn-kix_list_20-6 0;
}
.lst-kix_list_13-8 > li {
  counter-increment: lst-ctn-kix_list_13-8;
}
.lst-kix_list_16-5 > li {
  counter-increment: lst-ctn-kix_list_16-5;
}
.lst-kix_list_22-5 > li:before {
  content: '\0025aa   ';
}
.lst-kix_list_22-1 > li:before {
  content: 'o  ';
}
.lst-kix_list_20-2 > li {
  counter-increment: lst-ctn-kix_list_20-2;
}
.lst-kix_list_6-6 > li {
  counter-increment: lst-ctn-kix_list_6-6;
}
ol.lst-kix_list_15-7.start {
  counter-reset: lst-ctn-kix_list_15-7 0;
}
.lst-kix_list_13-6 > li {
  counter-increment: lst-ctn-kix_list_13-6;
}
ol.lst-kix_list_14-6.start {
  counter-reset: lst-ctn-kix_list_14-6 0;
}
.lst-kix_list_8-7 > li {
  counter-increment: lst-ctn-kix_list_8-7;
}
.lst-kix_list_19-6 > li {
  counter-increment: lst-ctn-kix_list_19-6;
}
.lst-kix_list_20-4 > li {
  counter-increment: lst-ctn-kix_list_20-4;
}
.lst-kix_list_9-5 > li {
  counter-increment: lst-ctn-kix_list_9-5;
}
li.li-bullet-3:before {
  margin-left: -11.3pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 11.3pt;
}
ol.lst-kix_list_19-4.start {
  counter-reset: lst-ctn-kix_list_19-4 0;
}
ol.lst-kix_list_20-1.start {
  counter-reset: lst-ctn-kix_list_20-1 0;
}
.lst-kix_list_25-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_19-4 > li {
  counter-increment: lst-ctn-kix_list_19-4;
}
.lst-kix_list_25-8 > li:before {
  content: '\0025aa   ';
}
ul.lst-kix_list_25-0 {
  list-style-type: none;
}
ol.lst-kix_list_14-7 {
  list-style-type: none;
}
ul.lst-kix_list_25-1 {
  list-style-type: none;
}
ol.lst-kix_list_14-8 {
  list-style-type: none;
}
ul.lst-kix_list_25-2 {
  list-style-type: none;
}
ul.lst-kix_list_25-3 {
  list-style-type: none;
}
ul.lst-kix_list_25-4 {
  list-style-type: none;
}
ol.lst-kix_list_14-3 {
  list-style-type: none;
}
li.li-bullet-8:before {
  margin-left: -11.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 11.4pt;
}
ol.lst-kix_list_14-4 {
  list-style-type: none;
}
ol.lst-kix_list_14-5 {
  list-style-type: none;
}
ol.lst-kix_list_14-6 {
  list-style-type: none;
}
.lst-kix_list_15-2 > li {
  counter-increment: lst-ctn-kix_list_15-2;
}
ol.lst-kix_list_14-0 {
  list-style-type: none;
}
ol.lst-kix_list_14-1 {
  list-style-type: none;
}
ol.lst-kix_list_14-2 {
  list-style-type: none;
}
.lst-kix_list_20-5 > li:before {
  content: '' counter(lst-ctn-kix_list_20-5, lower-roman) '. ';
}
ul.lst-kix_list_25-5 {
  list-style-type: none;
}
ul.lst-kix_list_25-6 {
  list-style-type: none;
}
.lst-kix_list_20-1 > li:before {
  content: '' counter(lst-ctn-kix_list_20-1, lower-latin) '. ';
}
ul.lst-kix_list_25-7 {
  list-style-type: none;
}
ul.lst-kix_list_25-8 {
  list-style-type: none;
}
.lst-kix_list_12-3 > li {
  counter-increment: lst-ctn-kix_list_12-3;
}
.lst-kix_list_17-3 > li {
  counter-increment: lst-ctn-kix_list_17-3;
}
ol.lst-kix_list_8-4.start {
  counter-reset: lst-ctn-kix_list_8-4 0;
}
li.li-bullet-45:before {
  margin-left: -21.3pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.3pt;
}
ol.lst-kix_list_13-0.start {
  counter-reset: lst-ctn-kix_list_13-0 0;
}
.lst-kix_list_14-4 > li {
  counter-increment: lst-ctn-kix_list_14-4;
}
.lst-kix_list_10-7 > li {
  counter-increment: lst-ctn-kix_list_10-7;
}
.lst-kix_list_18-1 > li {
  counter-increment: lst-ctn-kix_list_18-1;
}
ol.lst-kix_list_8-3.start {
  counter-reset: lst-ctn-kix_list_8-3 0;
}
.lst-kix_list_11-5 > li {
  counter-increment: lst-ctn-kix_list_11-5;
}
ol.lst-kix_list_14-5.start {
  counter-reset: lst-ctn-kix_list_14-5 0;
}
ol.lst-kix_list_20-3.start {
  counter-reset: lst-ctn-kix_list_20-3 0;
}
.lst-kix_list_4-1 > li {
  counter-increment: lst-ctn-kix_list_4-1;
}
.lst-kix_list_19-1 > li:before {
  content: '' counter(lst-ctn-kix_list_19-1, lower-latin) '. ';
}
.lst-kix_list_19-4 > li:before {
  content: '' counter(lst-ctn-kix_list_19-4, lower-latin) '. ';
}
.lst-kix_list_19-3 > li:before {
  content: '' counter(lst-ctn-kix_list_19-3, decimal) '. ';
}
.lst-kix_list_15-0 > li {
  counter-increment: lst-ctn-kix_list_15-0;
}
ol.lst-kix_list_6-6.start {
  counter-reset: lst-ctn-kix_list_6-6 0;
}
.lst-kix_list_11-0 > li {
  counter-increment: lst-ctn-kix_list_11-0;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-1 {
  list-style-type: none;
}
ul.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
ol.lst-kix_list_9-6.start {
  counter-reset: lst-ctn-kix_list_9-6 0;
}
ol.lst-kix_list_16-3.start {
  counter-reset: lst-ctn-kix_list_16-3 0;
}
ol.lst-kix_list_11-2.start {
  counter-reset: lst-ctn-kix_list_11-2 0;
}
.lst-kix_list_19-6 > li:before {
  content: '' counter(lst-ctn-kix_list_19-6, decimal) '. ';
}
ol.lst-kix_list_8-7.start {
  counter-reset: lst-ctn-kix_list_8-7 0;
}
.lst-kix_list_17-2 > li {
  counter-increment: lst-ctn-kix_list_17-2;
}
.lst-kix_list_20-5 > li {
  counter-increment: lst-ctn-kix_list_20-5;
}
.lst-kix_list_18-3 > li {
  counter-increment: lst-ctn-kix_list_18-3;
}
.lst-kix_list_10-3 > li {
  counter-increment: lst-ctn-kix_list_10-3;
}
ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.lst-kix_list_18-0 > li:before {
  content: '' counter(lst-ctn-kix_list_18-0, decimal) ') ';
}
ol.lst-kix_list_13-3.start {
  counter-reset: lst-ctn-kix_list_13-3 0;
}
.lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}
.lst-kix_list_18-2 > li:before {
  content: '' counter(lst-ctn-kix_list_18-2, lower-roman) '. ';
}
ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}
li.li-bullet-2:before {
  margin-left: -11.3pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 11.3pt;
}
ol.lst-kix_list_11-7.start {
  counter-reset: lst-ctn-kix_list_11-7 0;
}
ol.lst-kix_list_14-2.start {
  counter-reset: lst-ctn-kix_list_14-2 0;
}
.lst-kix_list_16-1 > li {
  counter-increment: lst-ctn-kix_list_16-1;
}
.lst-kix_list_17-5 > li {
  counter-increment: lst-ctn-kix_list_17-5;
}
ol.lst-kix_list_9-4.start {
  counter-reset: lst-ctn-kix_list_9-4 0;
}
ul.lst-kix_list_3-7 {
  list-style-type: none;
}
ul.lst-kix_list_3-8 {
  list-style-type: none;
}
.lst-kix_list_18-8 > li:before {
  content: '' counter(lst-ctn-kix_list_18-8, lower-roman) '. ';
}
ul.lst-kix_list_3-1 {
  list-style-type: none;
}
.lst-kix_list_17-6 > li {
  counter-increment: lst-ctn-kix_list_17-6;
}
ul.lst-kix_list_3-2 {
  list-style-type: none;
}
ul.lst-kix_list_3-5 {
  list-style-type: none;
}
ul.lst-kix_list_3-6 {
  list-style-type: none;
}
ul.lst-kix_list_3-3 {
  list-style-type: none;
}
ul.lst-kix_list_3-4 {
  list-style-type: none;
}
.lst-kix_list_10-7 > li:before {
  content: '' counter(lst-ctn-kix_list_10-7, lower-latin) '. ';
}
.lst-kix_list_7-8 > li {
  counter-increment: lst-ctn-kix_list_7-8;
}
.lst-kix_list_20-1 > li {
  counter-increment: lst-ctn-kix_list_20-1;
}
.lst-kix_list_10-5 > li:before {
  content: '' counter(lst-ctn-kix_list_10-5, lower-roman) '. ';
}
ol.lst-kix_list_13-5.start {
  counter-reset: lst-ctn-kix_list_13-5 0;
}
ol.lst-kix_list_18-6.start {
  counter-reset: lst-ctn-kix_list_18-6 0;
}
ol.lst-kix_list_20-8 {
  list-style-type: none;
}
ol.lst-kix_list_13-8.start {
  counter-reset: lst-ctn-kix_list_13-8 0;
}
ol.lst-kix_list_20-5 {
  list-style-type: none;
}
ol.lst-kix_list_20-4 {
  list-style-type: none;
}
ol.lst-kix_list_20-7 {
  list-style-type: none;
}
.lst-kix_list_11-7 > li {
  counter-increment: lst-ctn-kix_list_11-7;
}
ol.lst-kix_list_20-6 {
  list-style-type: none;
}
.lst-kix_list_9-2 > li:before {
  content: '' counter(lst-ctn-kix_list_9-2, lower-roman) '. ';
}
ol.lst-kix_list_20-1 {
  list-style-type: none;
}
ol.lst-kix_list_20-0 {
  list-style-type: none;
}
ol.lst-kix_list_20-3 {
  list-style-type: none;
}
ol.lst-kix_list_20-2 {
  list-style-type: none;
}
ol.lst-kix_list_14-0.start {
  counter-reset: lst-ctn-kix_list_14-0 0;
}
.lst-kix_list_12-5 > li {
  counter-increment: lst-ctn-kix_list_12-5;
}
.lst-kix_list_9-0 > li:before {
  content: '' counter(lst-ctn-kix_list_9-0, decimal) '. ';
}
ul.lst-kix_list_21-1 {
  list-style-type: none;
}
ul.lst-kix_list_21-2 {
  list-style-type: none;
}
ul.lst-kix_list_21-3 {
  list-style-type: none;
}
.lst-kix_list_16-8 > li {
  counter-increment: lst-ctn-kix_list_16-8;
}
ul.lst-kix_list_21-4 {
  list-style-type: none;
}
ul.lst-kix_list_21-5 {
  list-style-type: none;
}
ul.lst-kix_list_21-6 {
  list-style-type: none;
}
ul.lst-kix_list_21-7 {
  list-style-type: none;
}
ul.lst-kix_list_21-8 {
  list-style-type: none;
}
.lst-kix_list_11-3 > li:before {
  content: '' counter(lst-ctn-kix_list_11-3, decimal) '. ';
}
ul.lst-kix_list_21-0 {
  list-style-type: none;
}
.lst-kix_list_6-3 > li {
  counter-increment: lst-ctn-kix_list_6-3;
}
ol.lst-kix_list_18-4.start {
  counter-reset: lst-ctn-kix_list_18-4 0;
}
.lst-kix_list_20-4 > li:before {
  content: '' counter(lst-ctn-kix_list_20-4, lower-latin) '. ';
}
ol.lst-kix_list_6-1.start {
  counter-reset: lst-ctn-kix_list_6-1 0;
}
.lst-kix_list_20-2 > li:before {
  content: '' counter(lst-ctn-kix_list_20-2, lower-roman) '. ';
}
.lst-kix_list_9-8 > li:before {
  content: '' counter(lst-ctn-kix_list_9-8, lower-roman) '. ';
}
ol.lst-kix_list_16-8.start {
  counter-reset: lst-ctn-kix_list_16-8 0;
}
.lst-kix_list_1-7 > li:before {
  content: '\002022   ';
}
.lst-kix_list_1-5 > li:before {
  content: '\002022   ';
}
ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0;
}
li.li-bullet-30:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_2-1 > li:before {
  content: '\002022   ';
}
.lst-kix_list_19-8 > li {
  counter-increment: lst-ctn-kix_list_19-8;
}
li.li-bullet-41:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_2-3 > li:before {
  content: '\002022   ';
}
.lst-kix_list_11-8 > li {
  counter-increment: lst-ctn-kix_list_11-8;
}
.lst-kix_list_20-8 > li {
  counter-increment: lst-ctn-kix_list_20-8;
}
.lst-kix_list_9-1 > li {
  counter-increment: lst-ctn-kix_list_9-1;
}
.lst-kix_list_3-2 > li:before {
  content: '\002022   ';
}
.lst-kix_list_8-1 > li:before {
  content: '' counter(lst-ctn-kix_list_8-1, lower-latin) '. ';
}
.lst-kix_list_6-0 > li {
  counter-increment: lst-ctn-kix_list_6-0;
}
.lst-kix_list_3-5 > li:before {
  content: '\002022   ';
}
.lst-kix_list_18-0 > li {
  counter-increment: lst-ctn-kix_list_18-0;
}
li.li-bullet-37:before {
  margin-left: -15.1pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 15.1pt;
}
ol.lst-kix_list_11-5.start {
  counter-reset: lst-ctn-kix_list_11-5 0;
}
.lst-kix_list_11-1 > li {
  counter-increment: lst-ctn-kix_list_11-1;
}
.lst-kix_list_8-6 > li:before {
  content: '' counter(lst-ctn-kix_list_8-6, decimal) '. ';
}
.lst-kix_list_21-6 > li:before {
  content: '\0025cf   ';
}
ol.lst-kix_list_16-6.start {
  counter-reset: lst-ctn-kix_list_16-6 0;
}
li.li-bullet-48:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}
ol.lst-kix_list_16-0.start {
  counter-reset: lst-ctn-kix_list_16-0 0;
}
.lst-kix_list_21-3 > li:before {
  content: '\0025cf   ';
}
ol.lst-kix_list_18-7.start {
  counter-reset: lst-ctn-kix_list_18-7 0;
}
ol.lst-kix_list_11-6.start {
  counter-reset: lst-ctn-kix_list_11-6 0;
}
ol.lst-kix_list_6-4.start {
  counter-reset: lst-ctn-kix_list_6-4 0;
}
.lst-kix_list_17-1 > li:before {
  content: '' counter(lst-ctn-kix_list_17-1, lower-latin) '. ';
}
.lst-kix_list_25-3 > li:before {
  content: '\0025cf   ';
}
ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}
li.li-bullet-10:before {
  margin-left: -11.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 11.4pt;
}
.lst-kix_list_16-2 > li:before {
  content: '' counter(lst-ctn-kix_list_16-2, lower-roman) '. ';
}
.lst-kix_list_16-5 > li:before {
  content: '' counter(lst-ctn-kix_list_16-5, lower-roman) '. ';
}
.lst-kix_list_15-3 > li {
  counter-increment: lst-ctn-kix_list_15-3;
}
ol.lst-kix_list_11-0.start {
  counter-reset: lst-ctn-kix_list_11-0 0;
}
ol.lst-kix_list_18-8.start {
  counter-reset: lst-ctn-kix_list_18-8 0;
}
.lst-kix_list_16-4 > li {
  counter-increment: lst-ctn-kix_list_16-4;
}
ol.lst-kix_list_6-3.start {
  counter-reset: lst-ctn-kix_list_6-3 0;
}
ol.lst-kix_list_16-5.start {
  counter-reset: lst-ctn-kix_list_16-5 0;
}
.lst-kix_list_17-6 > li:before {
  content: '' counter(lst-ctn-kix_list_17-6, decimal) '. ';
}
.lst-kix_list_2-6 > li:before {
  content: '\002022   ';
}
ol.lst-kix_list_16-2.start {
  counter-reset: lst-ctn-kix_list_16-2 0;
}
.lst-kix_list_14-5 > li {
  counter-increment: lst-ctn-kix_list_14-5;
}
.lst-kix_list_7-5 > li:before {
  content: '' counter(lst-ctn-kix_list_7-5, lower-roman) '. ';
}
.lst-kix_list_19-5 > li {
  counter-increment: lst-ctn-kix_list_19-5;
}
.lst-kix_list_22-7 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_11-1.start {
  counter-reset: lst-ctn-kix_list_11-1 0;
}
.lst-kix_list_18-5 > li:before {
  content: '' counter(lst-ctn-kix_list_18-5, lower-roman) '. ';
}
.lst-kix_list_13-6 > li:before {
  content: '' counter(lst-ctn-kix_list_13-6, decimal) '. ';
}
.lst-kix_list_6-7 > li {
  counter-increment: lst-ctn-kix_list_6-7;
}
.lst-kix_list_10-6 > li {
  counter-increment: lst-ctn-kix_list_10-6;
}
.lst-kix_list_7-5 > li {
  counter-increment: lst-ctn-kix_list_7-5;
}
.lst-kix_list_15-6 > li:before {
  content: '' counter(lst-ctn-kix_list_15-6, decimal) '. ';
}
.lst-kix_list_11-4 > li {
  counter-increment: lst-ctn-kix_list_11-4;
}
li.li-bullet-15:before {
  margin-left: -7.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 7.4pt;
}
ol.lst-kix_list_6-8.start {
  counter-reset: lst-ctn-kix_list_6-8 0;
}
.lst-kix_list_10-2 > li:before {
  content: '' counter(lst-ctn-kix_list_10-2, lower-roman) '. ';
}
li.li-bullet-26:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_13-7 > li {
  counter-increment: lst-ctn-kix_list_13-7;
}
.lst-kix_list_20-7 > li:before {
  content: '' counter(lst-ctn-kix_list_20-7, lower-latin) '. ';
}
ol.lst-kix_list_6-5.start {
  counter-reset: lst-ctn-kix_list_6-5 0;
}
.lst-kix_list_4-6 > li:before {
  content: '\002022   ';
}
.lst-kix_list_25-6 > li:before {
  content: '\0025cf   ';
}
ol.lst-kix_list_6-7.start {
  counter-reset: lst-ctn-kix_list_6-7 0;
}
.lst-kix_list_12-2 > li {
  counter-increment: lst-ctn-kix_list_12-2;
}
.lst-kix_list_9-5 > li:before {
  content: '' counter(lst-ctn-kix_list_9-5, lower-roman) '. ';
}
li.li-bullet-5:before {
  margin-left: -8.6pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 8.6pt;
}
.lst-kix_list_12-2 > li:before {
  content: '' counter(lst-ctn-kix_list_12-2, lower-roman) '. ';
}
.lst-kix_list_11-6 > li:before {
  content: '' counter(lst-ctn-kix_list_11-6, decimal) '. ';
}
ol.lst-kix_list_11-3.start {
  counter-reset: lst-ctn-kix_list_11-3 0;
}
.lst-kix_list_1-2 > li:before {
  content: '\002022   ';
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
ol.lst-kix_list_16-1.start {
  counter-reset: lst-ctn-kix_list_16-1 0;
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_18-7 > li {
  counter-increment: lst-ctn-kix_list_18-7;
}
ol.lst-kix_list_11-4.start {
  counter-reset: lst-ctn-kix_list_11-4 0;
}
.lst-kix_list_14-1 > li:before {
  content: '' counter(lst-ctn-kix_list_14-1, lower-latin) '. ';
}
.lst-kix_list_14-3 > li:before {
  content: '' counter(lst-ctn-kix_list_14-3, decimal) '. ';
}
ol.lst-kix_list_15-6 {
  list-style-type: none;
}
ol.lst-kix_list_15-7 {
  list-style-type: none;
}
ol.lst-kix_list_15-8 {
  list-style-type: none;
}
.lst-kix_list_14-0 > li:before {
  content: '' counter(lst-ctn-kix_list_14-0, decimal) '. ';
}
.lst-kix_list_14-4 > li:before {
  content: '' counter(lst-ctn-kix_list_14-4, lower-latin) '. ';
}
ol.lst-kix_list_15-2 {
  list-style-type: none;
}
ol.lst-kix_list_15-3 {
  list-style-type: none;
}
ol.lst-kix_list_15-4 {
  list-style-type: none;
}
ol.lst-kix_list_18-5.start {
  counter-reset: lst-ctn-kix_list_18-5 0;
}
.lst-kix_list_6-1 > li {
  counter-increment: lst-ctn-kix_list_6-1;
}
.lst-kix_list_14-5 > li:before {
  content: '' counter(lst-ctn-kix_list_14-5, lower-roman) '. ';
}
.lst-kix_list_14-7 > li:before {
  content: '' counter(lst-ctn-kix_list_14-7, lower-latin) '. ';
}
ol.lst-kix_list_15-5 {
  list-style-type: none;
}
ol.lst-kix_list_15-0 {
  list-style-type: none;
}
.lst-kix_list_14-6 > li:before {
  content: '' counter(lst-ctn-kix_list_14-6, decimal) '. ';
}
ol.lst-kix_list_15-1 {
  list-style-type: none;
}
ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}
.lst-kix_list_17-0 > li {
  counter-increment: lst-ctn-kix_list_17-0;
}
.lst-kix_list_9-0 > li {
  counter-increment: lst-ctn-kix_list_9-0;
}
ol.lst-kix_list_11-8.start {
  counter-reset: lst-ctn-kix_list_11-8 0;
}
.lst-kix_list_14-2 > li:before {
  content: '' counter(lst-ctn-kix_list_14-2, lower-roman) '. ';
}
.lst-kix_list_20-7 > li {
  counter-increment: lst-ctn-kix_list_20-7;
}
ol.lst-kix_list_12-0.start {
  counter-reset: lst-ctn-kix_list_12-0 0;
}
.lst-kix_list_14-8 > li:before {
  content: '' counter(lst-ctn-kix_list_14-8, lower-roman) '. ';
}
ol.lst-kix_list_15-5.start {
  counter-reset: lst-ctn-kix_list_15-5 0;
}
.lst-kix_list_5-0 > li:before {
  content: '\0025cf   ';
}
ol.lst-kix_list_6-0 {
  list-style-type: none;
}
ol.lst-kix_list_6-1 {
  list-style-type: none;
}
.lst-kix_list_14-8 > li {
  counter-increment: lst-ctn-kix_list_14-8;
}
.lst-kix_list_5-3 > li:before {
  content: '\0025cf   ';
}
.lst-kix_list_5-2 > li:before {
  content: '\0025aa   ';
}
.lst-kix_list_8-3 > li {
  counter-increment: lst-ctn-kix_list_8-3;
}
.lst-kix_list_5-1 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_18-0.start {
  counter-reset: lst-ctn-kix_list_18-0 0;
}
.lst-kix_list_5-7 > li:before {
  content: 'o  ';
}
.lst-kix_list_5-6 > li:before {
  content: '\0025cf   ';
}
.lst-kix_list_5-8 > li:before {
  content: '\0025aa   ';
}
ol.lst-kix_list_6-6 {
  list-style-type: none;
}
ol.lst-kix_list_6-7 {
  list-style-type: none;
}
.lst-kix_list_5-4 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_6-8 {
  list-style-type: none;
}
.lst-kix_list_5-5 > li:before {
  content: '\0025aa   ';
}
ol.lst-kix_list_6-2 {
  list-style-type: none;
}
ol.lst-kix_list_6-3 {
  list-style-type: none;
}
ol.lst-kix_list_6-4 {
  list-style-type: none;
}
ol.lst-kix_list_6-5 {
  list-style-type: none;
}
li.li-bullet-36:before {
  margin-left: -15.1pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 15.1pt;
}
ol.lst-kix_list_12-5.start {
  counter-reset: lst-ctn-kix_list_12-5 0;
}
.lst-kix_list_6-1 > li:before {
  content: '' counter(lst-ctn-kix_list_6-1, lower-latin) '. ';
}
.lst-kix_list_6-3 > li:before {
  content: '' counter(lst-ctn-kix_list_6-3, decimal) '. ';
}
.lst-kix_list_6-8 > li {
  counter-increment: lst-ctn-kix_list_6-8;
}
.lst-kix_list_6-0 > li:before {
  content: '' counter(lst-ctn-kix_list_6-0, lower-latin) '. ';
}
.lst-kix_list_6-4 > li:before {
  content: '' counter(lst-ctn-kix_list_6-4, lower-latin) '. ';
}
ol.lst-kix_list_14-8.start {
  counter-reset: lst-ctn-kix_list_14-8 0;
}
li.li-bullet-25:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_6-2 > li:before {
  content: '' counter(lst-ctn-kix_list_6-2, lower-roman) '. ';
}
ol.lst-kix_list_15-0.start {
  counter-reset: lst-ctn-kix_list_15-0 0;
}
.lst-kix_list_6-8 > li:before {
  content: '' counter(lst-ctn-kix_list_6-8, lower-roman) '. ';
}
li.li-bullet-47:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_6-5 > li:before {
  content: '' counter(lst-ctn-kix_list_6-5, lower-roman) '. ';
}
.lst-kix_list_6-7 > li:before {
  content: '' counter(lst-ctn-kix_list_6-7, lower-latin) '. ';
}
.lst-kix_list_6-6 > li:before {
  content: '' counter(lst-ctn-kix_list_6-6, decimal) '. ';
}
ol.lst-kix_list_17-8 {
  list-style-type: none;
}
ol.lst-kix_list_10-6.start {
  counter-reset: lst-ctn-kix_list_10-6 0;
}
.lst-kix_list_7-4 > li:before {
  content: '' counter(lst-ctn-kix_list_7-4, lower-latin) '. ';
}
.lst-kix_list_7-6 > li:before {
  content: '' counter(lst-ctn-kix_list_7-6, decimal) '. ';
}
ol.lst-kix_list_17-4 {
  list-style-type: none;
}
.lst-kix_list_18-5 > li {
  counter-increment: lst-ctn-kix_list_18-5;
}
ol.lst-kix_list_19-7.start {
  counter-reset: lst-ctn-kix_list_19-7 0;
}
ol.lst-kix_list_17-5 {
  list-style-type: none;
}
ol.lst-kix_list_6-2.start {
  counter-reset: lst-ctn-kix_list_6-2 0;
}
.lst-kix_list_15-5 > li {
  counter-increment: lst-ctn-kix_list_15-5;
}
ol.lst-kix_list_17-6 {
  list-style-type: none;
}
ol.lst-kix_list_17-7 {
  list-style-type: none;
}
ol.lst-kix_list_17-0 {
  list-style-type: none;
}
ol.lst-kix_list_17-1 {
  list-style-type: none;
}
ol.lst-kix_list_17-2 {
  list-style-type: none;
}
.lst-kix_list_7-2 > li:before {
  content: '' counter(lst-ctn-kix_list_7-2, lower-roman) '. ';
}
ol.lst-kix_list_17-3 {
  list-style-type: none;
}
.lst-kix_list_7-6 > li {
  counter-increment: lst-ctn-kix_list_7-6;
}
.lst-kix_list_12-6 > li {
  counter-increment: lst-ctn-kix_list_12-6;
}
.lst-kix_list_13-7 > li:before {
  content: '' counter(lst-ctn-kix_list_13-7, lower-latin) '. ';
}
.lst-kix_list_7-8 > li:before {
  content: '' counter(lst-ctn-kix_list_7-8, lower-roman) '. ';
}
.lst-kix_list_15-6 > li {
  counter-increment: lst-ctn-kix_list_15-6;
}
.lst-kix_list_15-5 > li:before {
  content: '' counter(lst-ctn-kix_list_15-5, lower-roman) '. ';
}
.lst-kix_list_9-8 > li {
  counter-increment: lst-ctn-kix_list_9-8;
}
.lst-kix_list_13-4 > li {
  counter-increment: lst-ctn-kix_list_13-4;
}
.lst-kix_list_4-1 > li:before {
  content: '' counter(lst-ctn-kix_list_4-1, decimal) '. ';
}
.lst-kix_list_15-7 > li:before {
  content: '' counter(lst-ctn-kix_list_15-7, lower-latin) '. ';
}
.lst-kix_list_17-7 > li {
  counter-increment: lst-ctn-kix_list_17-7;
}
.lst-kix_list_4-3 > li:before {
  content: '\002022   ';
}
.lst-kix_list_4-5 > li:before {
  content: '\002022   ';
}
.lst-kix_list_10-5 > li {
  counter-increment: lst-ctn-kix_list_10-5;
}
.lst-kix_list_15-1 > li:before {
  content: '' counter(lst-ctn-kix_list_15-1, lower-latin) '. ';
}
.lst-kix_list_15-3 > li:before {
  content: '' counter(lst-ctn-kix_list_15-3, decimal) '. ';
}
.lst-kix_list_16-2 > li {
  counter-increment: lst-ctn-kix_list_16-2;
}
ol.lst-kix_list_9-2.start {
  counter-reset: lst-ctn-kix_list_9-2 0;
}
.lst-kix_list_20-0 > li {
  counter-increment: lst-ctn-kix_list_20-0;
}
ol.lst-kix_list_16-7.start {
  counter-reset: lst-ctn-kix_list_16-7 0;
}
.lst-kix_list_11-2 > li {
  counter-increment: lst-ctn-kix_list_11-2;
}
.lst-kix_list_19-2 > li {
  counter-increment: lst-ctn-kix_list_19-2;
}
ol.lst-kix_list_8-8 {
  list-style-type: none;
}
.lst-kix_list_12-3 > li:before {
  content: '' counter(lst-ctn-kix_list_12-3, decimal) '. ';
}
ol.lst-kix_list_8-4 {
  list-style-type: none;
}
.lst-kix_list_12-1 > li:before {
  content: '' counter(lst-ctn-kix_list_12-1, lower-latin) '. ';
}
ol.lst-kix_list_8-5 {
  list-style-type: none;
}
ol.lst-kix_list_8-6 {
  list-style-type: none;
}
ol.lst-kix_list_8-7 {
  list-style-type: none;
}
ol.lst-kix_list_8-0 {
  list-style-type: none;
}
.lst-kix_list_16-3 > li {
  counter-increment: lst-ctn-kix_list_16-3;
}
ol.lst-kix_list_8-1 {
  list-style-type: none;
}
ol.lst-kix_list_8-2 {
  list-style-type: none;
}
.lst-kix_list_13-3 > li {
  counter-increment: lst-ctn-kix_list_13-3;
}
ol.lst-kix_list_13-6.start {
  counter-reset: lst-ctn-kix_list_13-6 0;
}
ol.lst-kix_list_8-3 {
  list-style-type: none;
}
.lst-kix_list_10-4 > li {
  counter-increment: lst-ctn-kix_list_10-4;
}
.lst-kix_list_14-1 > li {
  counter-increment: lst-ctn-kix_list_14-1;
}
.lst-kix_list_13-3 > li:before {
  content: '' counter(lst-ctn-kix_list_13-3, decimal) '. ';
}
li.li-bullet-4:before {
  margin-left: -8.6pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 8.6pt;
}
.lst-kix_list_13-5 > li:before {
  content: '' counter(lst-ctn-kix_list_13-5, lower-roman) '. ';
}
.lst-kix_list_12-5 > li:before {
  content: '' counter(lst-ctn-kix_list_12-5, lower-roman) '. ';
}
ol.lst-kix_list_13-7.start {
  counter-reset: lst-ctn-kix_list_13-7 0;
}
.lst-kix_list_18-4 > li {
  counter-increment: lst-ctn-kix_list_18-4;
}
.lst-kix_list_12-7 > li:before {
  content: '' counter(lst-ctn-kix_list_12-7, lower-latin) '. ';
}
.lst-kix_list_13-1 > li:before {
  content: '' counter(lst-ctn-kix_list_13-1, lower-latin) '. ';
}
ul.lst-kix_list_24-0 {
  list-style-type: none;
}
ul.lst-kix_list_24-1 {
  list-style-type: none;
}
ul.lst-kix_list_24-2 {
  list-style-type: none;
}
ul.lst-kix_list_24-3 {
  list-style-type: none;
}
ul.lst-kix_list_24-4 {
  list-style-type: none;
}
ul.lst-kix_list_24-5 {
  list-style-type: none;
}
ol.lst-kix_list_11-6 {
  list-style-type: none;
}
ol.lst-kix_list_11-7 {
  list-style-type: none;
}
ol.lst-kix_list_11-8 {
  list-style-type: none;
}
ol.lst-kix_list_11-2 {
  list-style-type: none;
}
ol.lst-kix_list_11-3 {
  list-style-type: none;
}
.lst-kix_list_3-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_3-0, lower-roman) ') ';
}
ol.lst-kix_list_11-4 {
  list-style-type: none;
}
ol.lst-kix_list_11-5 {
  list-style-type: none;
}
ol.lst-kix_list_20-5.start {
  counter-reset: lst-ctn-kix_list_20-5 0;
}
ol.lst-kix_list_13-1.start {
  counter-reset: lst-ctn-kix_list_13-1 0;
}
ol.lst-kix_list_11-0 {
  list-style-type: none;
}
ol.lst-kix_list_11-1 {
  list-style-type: none;
}
.lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}
ul.lst-kix_list_24-6 {
  list-style-type: none;
}
ul.lst-kix_list_24-7 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
  content: '\002022   ';
}
ul.lst-kix_list_24-8 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: '\002022   ';
}
.lst-kix_list_8-0 > li:before {
  content: '' counter(lst-ctn-kix_list_8-0, lower-latin) '. ';
}
.lst-kix_list_8-7 > li:before {
  content: '' counter(lst-ctn-kix_list_8-7, lower-latin) '. ';
}
li.li-bullet-44:before {
  margin-left: -21.3pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.3pt;
}
.lst-kix_list_3-8 > li:before {
  content: '\002022   ';
}
ol.lst-kix_list_10-7.start {
  counter-reset: lst-ctn-kix_list_10-7 0;
}
.lst-kix_list_8-3 > li:before {
  content: '' counter(lst-ctn-kix_list_8-3, decimal) '. ';
}
li.li-bullet-39:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
li.li-bullet-33:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_3-7 > li:before {
  content: '\002022   ';
}
.lst-kix_list_8-4 > li:before {
  content: '' counter(lst-ctn-kix_list_8-4, lower-latin) '. ';
}
.lst-kix_list_19-1 > li {
  counter-increment: lst-ctn-kix_list_19-1;
}
ol.lst-kix_list_8-5.start {
  counter-reset: lst-ctn-kix_list_8-5 0;
}
.lst-kix_list_17-1 > li {
  counter-increment: lst-ctn-kix_list_17-1;
}
.lst-kix_list_11-1 > li:before {
  content: '' counter(lst-ctn-kix_list_11-1, lower-latin) '. ';
}
.lst-kix_list_11-0 > li:before {
  content: '' counter(lst-ctn-kix_list_11-0, decimal) '. ';
}
ol.lst-kix_list_9-3.start {
  counter-reset: lst-ctn-kix_list_9-3 0;
}
.lst-kix_list_8-8 > li:before {
  content: '' counter(lst-ctn-kix_list_8-8, lower-roman) '. ';
}
.lst-kix_list_16-8 > li:before {
  content: '' counter(lst-ctn-kix_list_16-8, lower-roman) '. ';
}
.lst-kix_list_16-7 > li:before {
  content: '' counter(lst-ctn-kix_list_16-7, lower-latin) '. ';
}
.lst-kix_list_17-8 > li {
  counter-increment: lst-ctn-kix_list_17-8;
}
ol.lst-kix_list_2-0 {
  list-style-type: none;
}
.lst-kix_list_4-8 > li:before {
  content: '\002022   ';
}
.lst-kix_list_4-7 > li:before {
  content: '\002022   ';
}
.lst-kix_list_14-2 > li {
  counter-increment: lst-ctn-kix_list_14-2;
}
ol.lst-kix_list_20-0.start {
  counter-reset: lst-ctn-kix_list_20-0 0;
}
.lst-kix_list_17-0 > li:before {
  content: '' counter(lst-ctn-kix_list_17-0, decimal) '. ';
}
li.li-bullet-7:before {
  margin-left: -11.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 11.4pt;
}
ul.lst-kix_list_4-8 {
  list-style-type: none;
}
.lst-kix_list_16-0 > li:before {
  content: '' counter(lst-ctn-kix_list_16-0, decimal) '. ';
}
ul.lst-kix_list_4-6 {
  list-style-type: none;
}
ul.lst-kix_list_4-7 {
  list-style-type: none;
}
.lst-kix_list_8-4 > li {
  counter-increment: lst-ctn-kix_list_8-4;
}
.lst-kix_list_16-4 > li:before {
  content: '' counter(lst-ctn-kix_list_16-4, lower-latin) '. ';
}
.lst-kix_list_16-3 > li:before {
  content: '' counter(lst-ctn-kix_list_16-3, decimal) '. ';
}
ul.lst-kix_list_4-4 {
  list-style-type: none;
}
ul.lst-kix_list_4-5 {
  list-style-type: none;
}
ul.lst-kix_list_4-3 {
  list-style-type: none;
}
.lst-kix_list_11-3 > li {
  counter-increment: lst-ctn-kix_list_11-3;
}
ol.lst-kix_list_18-1.start {
  counter-reset: lst-ctn-kix_list_18-1 0;
}
ol.lst-kix_list_8-6.start {
  counter-reset: lst-ctn-kix_list_8-6 0;
}
.lst-kix_list_17-7 > li:before {
  content: '' counter(lst-ctn-kix_list_17-7, lower-latin) '. ';
}
.lst-kix_list_17-8 > li:before {
  content: '' counter(lst-ctn-kix_list_17-8, lower-roman) '. ';
}
.lst-kix_list_17-3 > li:before {
  content: '' counter(lst-ctn-kix_list_17-3, decimal) '. ';
}
.lst-kix_list_17-4 > li:before {
  content: '' counter(lst-ctn-kix_list_17-4, lower-latin) '. ';
}
ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 0;
}
.lst-kix_list_7-0 > li:before {
  content: '' counter(lst-ctn-kix_list_7-0, decimal) '. ';
}
ol.lst-kix_list_19-6.start {
  counter-reset: lst-ctn-kix_list_19-6 0;
}
ol.lst-kix_list_9-7.start {
  counter-reset: lst-ctn-kix_list_9-7 0;
}
ol.lst-kix_list_13-8 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
  content: '\002022   ';
}
.lst-kix_list_2-8 > li:before {
  content: '\002022   ';
}
ol.lst-kix_list_13-4 {
  list-style-type: none;
}
ol.lst-kix_list_13-5 {
  list-style-type: none;
}
ol.lst-kix_list_13-6 {
  list-style-type: none;
}
ol.lst-kix_list_13-7 {
  list-style-type: none;
}
ol.lst-kix_list_13-0 {
  list-style-type: none;
}
ol.lst-kix_list_13-1 {
  list-style-type: none;
}
ol.lst-kix_list_13-2 {
  list-style-type: none;
}
.lst-kix_list_7-3 > li:before {
  content: '' counter(lst-ctn-kix_list_7-3, decimal) '. ';
}
ol.lst-kix_list_13-3 {
  list-style-type: none;
}
.lst-kix_list_10-0 > li:before {
  content: '' counter(lst-ctn-kix_list_10-0, decimal) '. ';
}
.lst-kix_list_9-7 > li {
  counter-increment: lst-ctn-kix_list_9-7;
}
.lst-kix_list_13-8 > li:before {
  content: '' counter(lst-ctn-kix_list_13-8, lower-roman) '. ';
}
.lst-kix_list_18-3 > li:before {
  content: '' counter(lst-ctn-kix_list_18-3, decimal) '. ';
}
.lst-kix_list_18-7 > li:before {
  content: '' counter(lst-ctn-kix_list_18-7, lower-latin) '. ';
}
.lst-kix_list_18-6 > li {
  counter-increment: lst-ctn-kix_list_18-6;
}
.lst-kix_list_7-7 > li:before {
  content: '' counter(lst-ctn-kix_list_7-7, lower-latin) '. ';
}
ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0;
}
.lst-kix_list_15-4 > li:before {
  content: '' counter(lst-ctn-kix_list_15-4, lower-latin) '. ';
}
ol.lst-kix_list_19-1.start {
  counter-reset: lst-ctn-kix_list_19-1 0;
}
li.li-bullet-17:before {
  margin-left: -9.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 9.2pt;
}
.lst-kix_list_10-4 > li:before {
  content: '' counter(lst-ctn-kix_list_10-4, lower-latin) '. ';
}
.lst-kix_list_10-8 > li:before {
  content: '' counter(lst-ctn-kix_list_10-8, lower-roman) '. ';
}
ol.lst-kix_list_20-4.start {
  counter-reset: lst-ctn-kix_list_20-4 0;
}
.lst-kix_list_4-0 > li:before {
  content: '' counter(lst-ctn-kix_list_4-0, upper-roman) '. ';
}
.lst-kix_list_15-0 > li:before {
  content: '' counter(lst-ctn-kix_list_15-0, decimal) '. ';
}
.lst-kix_list_15-8 > li:before {
  content: '' counter(lst-ctn-kix_list_15-8, lower-roman) '. ';
}
ol.lst-kix_list_14-3.start {
  counter-reset: lst-ctn-kix_list_14-3 0;
}
.lst-kix_list_15-7 > li {
  counter-increment: lst-ctn-kix_list_15-7;
}
li.li-bullet-28:before {
  margin-left: -8pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 8pt;
}
.lst-kix_list_4-4 > li:before {
  content: '\002022   ';
}
.lst-kix_list_9-3 > li:before {
  content: '' counter(lst-ctn-kix_list_9-3, decimal) '. ';
}
ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 0;
}
.lst-kix_list_12-8 > li {
  counter-increment: lst-ctn-kix_list_12-8;
}
ol.lst-kix_list_13-2.start {
  counter-reset: lst-ctn-kix_list_13-2 0;
}
ol.lst-kix_list_4-0 {
  list-style-type: none;
}
ol.lst-kix_list_4-1 {
  list-style-type: none;
}
ol.lst-kix_list_4-2 {
  list-style-type: none;
}
ol.lst-kix_list_14-4.start {
  counter-reset: lst-ctn-kix_list_14-4 0;
}
.lst-kix_list_9-7 > li:before {
  content: '' counter(lst-ctn-kix_list_9-7, lower-latin) '. ';
}
.lst-kix_list_11-4 > li:before {
  content: '' counter(lst-ctn-kix_list_11-4, lower-latin) '. ';
}
.lst-kix_list_12-4 > li:before {
  content: '' counter(lst-ctn-kix_list_12-4, lower-latin) '. ';
}
li.li-bullet-12:before {
  margin-left: -7.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 7.4pt;
}
.lst-kix_list_7-4 > li {
  counter-increment: lst-ctn-kix_list_7-4;
}
.lst-kix_list_1-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-0, lower-roman) ') ';
}
ol.lst-kix_list_19-2.start {
  counter-reset: lst-ctn-kix_list_19-2 0;
}
.lst-kix_list_11-8 > li:before {
  content: '' counter(lst-ctn-kix_list_11-8, lower-roman) '. ';
}
ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
.lst-kix_list_12-0 > li:before {
  content: '' counter(lst-ctn-kix_list_12-0, lower-latin) '. ';
}
.lst-kix_list_1-4 > li:before {
  content: '\002022   ';
}
.lst-kix_list_13-0 > li:before {
  content: '' counter(lst-ctn-kix_list_13-0, lower-roman) '. ';
}
li.li-bullet-34:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_13-4 > li:before {
  content: '' counter(lst-ctn-kix_list_13-4, lower-latin) '. ';
}
li.li-bullet-23:before {
  margin-left: -21.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.2pt;
}
ol.lst-kix_list_19-3.start {
  counter-reset: lst-ctn-kix_list_19-3 0;
}
.lst-kix_list_2-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_2-0, lower-latin) ') ';
}
ol.lst-kix_list_9-8.start {
  counter-reset: lst-ctn-kix_list_9-8 0;
}
.lst-kix_list_1-8 > li:before {
  content: '\002022   ';
}
.lst-kix_list_12-8 > li:before {
  content: '' counter(lst-ctn-kix_list_12-8, lower-roman) '. ';
}
.lst-kix_list_8-2 > li {
  counter-increment: lst-ctn-kix_list_8-2;
}
.lst-kix_list_19-0 > li:before {
  content: '' counter(lst-ctn-kix_list_19-0, decimal) ') ';
}
ol.lst-kix_list_17-7.start {
  counter-reset: lst-ctn-kix_list_17-7 0;
}
ol.lst-kix_list_12-6.start {
  counter-reset: lst-ctn-kix_list_12-6 0;
}
.lst-kix_list_8-1 > li {
  counter-increment: lst-ctn-kix_list_8-1;
}
ol.lst-kix_list_8-2.start {
  counter-reset: lst-ctn-kix_list_8-2 0;
}
.lst-kix_list_19-2 > li:before {
  content: '' counter(lst-ctn-kix_list_19-2, lower-roman) '. ';
}
.lst-kix_list_7-0 > li {
  counter-increment: lst-ctn-kix_list_7-0;
}
.lst-kix_list_19-0 > li {
  counter-increment: lst-ctn-kix_list_19-0;
}
ol.lst-kix_list_19-8.start {
  counter-reset: lst-ctn-kix_list_19-8 0;
}
.lst-kix_list_19-8 > li:before {
  content: '' counter(lst-ctn-kix_list_19-8, lower-roman) '. ';
}
ol.lst-kix_list_14-7.start {
  counter-reset: lst-ctn-kix_list_14-7 0;
}
ol.lst-kix_list_20-8.start {
  counter-reset: lst-ctn-kix_list_20-8 0;
}
.lst-kix_list_19-5 > li:before {
  content: '' counter(lst-ctn-kix_list_19-5, lower-roman) '. ';
}
.lst-kix_list_19-7 > li:before {
  content: '' counter(lst-ctn-kix_list_19-7, lower-latin) '. ';
}
.lst-kix_list_9-2 > li {
  counter-increment: lst-ctn-kix_list_9-2;
}
ol.lst-kix_list_17-2.start {
  counter-reset: lst-ctn-kix_list_17-2 0;
}
.lst-kix_list_13-2 > li {
  counter-increment: lst-ctn-kix_list_13-2;
}
li.li-bullet-6:before {
  margin-left: -11.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 11.4pt;
}
.lst-kix_list_19-7 > li {
  counter-increment: lst-ctn-kix_list_19-7;
}
.lst-kix_list_14-3 > li {
  counter-increment: lst-ctn-kix_list_14-3;
}
ol.lst-kix_list_15-6.start {
  counter-reset: lst-ctn-kix_list_15-6 0;
}
.lst-kix_list_12-1 > li {
  counter-increment: lst-ctn-kix_list_12-1;
}
.lst-kix_list_18-1 > li:before {
  content: '' counter(lst-ctn-kix_list_18-1, lower-latin) '. ';
}
ul.lst-kix_list_22-0 {
  list-style-type: none;
}
.lst-kix_list_2-7 > li:before {
  content: '\002022   ';
}
ul.lst-kix_list_22-1 {
  list-style-type: none;
}
ul.lst-kix_list_22-2 {
  list-style-type: none;
}
ul.lst-kix_list_22-3 {
  list-style-type: none;
}
ul.lst-kix_list_22-4 {
  list-style-type: none;
}
ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_2-5 > li:before {
  content: '\002022   ';
}
ul.lst-kix_list_22-5 {
  list-style-type: none;
}
ul.lst-kix_list_22-6 {
  list-style-type: none;
}
ul.lst-kix_list_22-7 {
  list-style-type: none;
}
ol.lst-kix_list_17-0.start {
  counter-reset: lst-ctn-kix_list_17-0 0;
}
ol.lst-kix_list_10-3.start {
  counter-reset: lst-ctn-kix_list_10-3 0;
}
.lst-kix_list_18-6 > li:before {
  content: '' counter(lst-ctn-kix_list_18-6, decimal) '. ';
}
.lst-kix_list_14-6 > li {
  counter-increment: lst-ctn-kix_list_14-6;
}
.lst-kix_list_10-1 > li:before {
  content: '' counter(lst-ctn-kix_list_10-1, lower-latin) '. ';
}
.lst-kix_list_18-4 > li:before {
  content: '' counter(lst-ctn-kix_list_18-4, lower-latin) '. ';
}
ul.lst-kix_list_22-8 {
  list-style-type: none;
}
.lst-kix_list_7-7 > li {
  counter-increment: lst-ctn-kix_list_7-7;
}
ol.lst-kix_list_15-1.start {
  counter-reset: lst-ctn-kix_list_15-1 0;
}
ol.lst-kix_list_15-4.start {
  counter-reset: lst-ctn-kix_list_15-4 0;
}
li.li-bullet-19:before {
  margin-left: -7.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 7.4pt;
}
li.li-bullet-1:before {
  margin-left: -7.3pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 7.3pt;
}
.lst-kix_list_10-3 > li:before {
  content: '' counter(lst-ctn-kix_list_10-3, decimal) '. ';
}
.lst-kix_list_15-4 > li {
  counter-increment: lst-ctn-kix_list_15-4;
}
ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}
li.li-bullet-22:before {
  margin-left: -21.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.2pt;
}
.lst-kix_list_20-8 > li:before {
  content: '' counter(lst-ctn-kix_list_20-8, lower-roman) '. ';
}
.lst-kix_list_20-0 > li:before {
  content: '' counter(lst-ctn-kix_list_20-0, decimal) '. ';
}
ol.lst-kix_list_10-7 {
  list-style-type: none;
}
.lst-kix_list_9-6 > li:before {
  content: '' counter(lst-ctn-kix_list_9-6, decimal) '. ';
}
li.li-bullet-9:before {
  margin-left: -11.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 11.4pt;
}
ol.lst-kix_list_10-8 {
  list-style-type: none;
}
ol.lst-kix_list_10-3 {
  list-style-type: none;
}
.lst-kix_list_9-4 > li:before {
  content: '' counter(lst-ctn-kix_list_9-4, lower-latin) '. ';
}
ol.lst-kix_list_10-4 {
  list-style-type: none;
}
ol.lst-kix_list_10-5 {
  list-style-type: none;
}
ol.lst-kix_list_10-6 {
  list-style-type: none;
}
.lst-kix_list_20-6 > li:before {
  content: '' counter(lst-ctn-kix_list_20-6, decimal) '. ';
}
ol.lst-kix_list_10-0 {
  list-style-type: none;
}
ol.lst-kix_list_10-1 {
  list-style-type: none;
}
ul.lst-kix_list_2-8 {
  list-style-type: none;
}
ol.lst-kix_list_10-2 {
  list-style-type: none;
}
ol.lst-kix_list_12-1.start {
  counter-reset: lst-ctn-kix_list_12-1 0;
}
li.li-bullet-11:before {
  margin-left: -7.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 7.4pt;
}
.lst-kix_list_11-5 > li:before {
  content: '' counter(lst-ctn-kix_list_11-5, lower-roman) '. ';
}
ul.lst-kix_list_2-2 {
  list-style-type: none;
}
ul.lst-kix_list_2-3 {
  list-style-type: none;
}
ul.lst-kix_list_2-1 {
  list-style-type: none;
}
ul.lst-kix_list_2-6 {
  list-style-type: none;
}
.lst-kix_list_20-6 > li {
  counter-increment: lst-ctn-kix_list_20-6;
}
.lst-kix_list_1-1 > li:before {
  content: '\002022   ';
}
ul.lst-kix_list_2-7 {
  list-style-type: none;
}
.lst-kix_list_11-7 > li:before {
  content: '' counter(lst-ctn-kix_list_11-7, lower-latin) '. ';
}
ul.lst-kix_list_2-4 {
  list-style-type: none;
}
ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_8-5 > li {
  counter-increment: lst-ctn-kix_list_8-5;
}
.lst-kix_list_1-3 > li:before {
  content: '\002022   ';
}
li.li-bullet-38:before {
  margin-left: -35.5pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 35.5pt;
}
ol.lst-kix_list_10-5.start {
  counter-reset: lst-ctn-kix_list_10-5 0;
}
li.li-bullet-16:before {
  margin-left: -11.3pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 11.3pt;
}
li.li-bullet-27:before {
  margin-left: -7.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 7.4pt;
}
.lst-kix_list_14-7 > li {
  counter-increment: lst-ctn-kix_list_14-7;
}
ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}
ol.lst-kix_list_19-6 {
  list-style-type: none;
}
ol.lst-kix_list_19-7 {
  list-style-type: none;
}
ol.lst-kix_list_19-8 {
  list-style-type: none;
}
ol.lst-kix_list_19-2 {
  list-style-type: none;
}
ol.lst-kix_list_19-3 {
  list-style-type: none;
}
ol.lst-kix_list_19-4 {
  list-style-type: none;
}
ol.lst-kix_list_19-5 {
  list-style-type: none;
}
ol.lst-kix_list_19-0 {
  list-style-type: none;
}
ol.lst-kix_list_19-1 {
  list-style-type: none;
}
ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}
.lst-kix_list_3-1 > li:before {
  content: '\002022   ';
}
.lst-kix_list_14-0 > li {
  counter-increment: lst-ctn-kix_list_14-0;
}
ol.lst-kix_list_17-4.start {
  counter-reset: lst-ctn-kix_list_17-4 0;
}
.lst-kix_list_8-2 > li:before {
  content: '' counter(lst-ctn-kix_list_8-2, lower-roman) '. ';
}
.lst-kix_list_12-0 > li {
  counter-increment: lst-ctn-kix_list_12-0;
}
ol.lst-kix_list_12-3.start {
  counter-reset: lst-ctn-kix_list_12-3 0;
}
.lst-kix_list_21-2 > li:before {
  content: '\0025aa   ';
}
.lst-kix_list_8-5 > li:before {
  content: '' counter(lst-ctn-kix_list_8-5, lower-roman) '. ';
}
li.li-bullet-42:before {
  margin-left: -14.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 14.2pt;
}
.lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
.lst-kix_list_15-1 > li {
  counter-increment: lst-ctn-kix_list_15-1;
}
.lst-kix_list_3-6 > li:before {
  content: '\002022   ';
}
li.li-bullet-31:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_21-7 > li:before {
  content: 'o  ';
}
.lst-kix_list_11-2 > li:before {
  content: '' counter(lst-ctn-kix_list_11-2, lower-roman) '. ';
}
ol.lst-kix_list_12-4.start {
  counter-reset: lst-ctn-kix_list_12-4 0;
}
.lst-kix_list_16-6 > li:before {
  content: '' counter(lst-ctn-kix_list_16-6, decimal) '. ';
}
ol.lst-kix_list_10-1.start {
  counter-reset: lst-ctn-kix_list_10-1 0;
}
.lst-kix_list_25-2 > li:before {
  content: '\0025aa   ';
}
.lst-kix_list_18-2 > li {
  counter-increment: lst-ctn-kix_list_18-2;
}
.lst-kix_list_16-1 > li:before {
  content: '' counter(lst-ctn-kix_list_16-1, lower-latin) '. ';
}
.lst-kix_list_7-3 > li {
  counter-increment: lst-ctn-kix_list_7-3;
}
li.li-bullet-14:before {
  margin-left: -7.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 7.4pt;
}
.lst-kix_list_19-3 > li {
  counter-increment: lst-ctn-kix_list_19-3;
}
ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}
.lst-kix_list_12-4 > li {
  counter-increment: lst-ctn-kix_list_12-4;
}
ol.lst-kix_list_10-2.start {
  counter-reset: lst-ctn-kix_list_10-2 0;
}
.lst-kix_list_12-7 > li {
  counter-increment: lst-ctn-kix_list_12-7;
}
.lst-kix_list_17-2 > li:before {
  content: '' counter(lst-ctn-kix_list_17-2, lower-roman) '. ';
}
ol.lst-kix_list_17-3.start {
  counter-reset: lst-ctn-kix_list_17-3 0;
}
.lst-kix_list_17-5 > li:before {
  content: '' counter(lst-ctn-kix_list_17-5, lower-roman) '. ';
}
.lst-kix_list_6-2 > li {
  counter-increment: lst-ctn-kix_list_6-2;
}
.lst-kix_list_22-3 > li:before {
  content: '\0025cf   ';
}
.lst-kix_list_7-1 > li:before {
  content: '' counter(lst-ctn-kix_list_7-1, lower-latin) '. ';
}
.lst-kix_list_13-5 > li {
  counter-increment: lst-ctn-kix_list_13-5;
}
.lst-kix_list_9-6 > li {
  counter-increment: lst-ctn-kix_list_9-6;
}
li.li-bullet-20:before {
  margin-left: -7.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 7.4pt;
}
.lst-kix_list_20-3 > li {
  counter-increment: lst-ctn-kix_list_20-3;
}
.lst-kix_list_16-6 > li {
  counter-increment: lst-ctn-kix_list_16-6;
}
.lst-kix_list_11-6 > li {
  counter-increment: lst-ctn-kix_list_11-6;
}
ol.lst-kix_list_10-0.start {
  counter-reset: lst-ctn-kix_list_10-0 0;
}
ol.lst-kix_list_17-8.start {
  counter-reset: lst-ctn-kix_list_17-8 0;
}
ol.lst-kix_list_17-5.start {
  counter-reset: lst-ctn-kix_list_17-5 0;
}
.lst-kix_list_4-2 > li:before {
  content: '' counter(lst-ctn-kix_list_4-2, decimal) ') ';
}
.lst-kix_list_17-4 > li {
  counter-increment: lst-ctn-kix_list_17-4;
}
.lst-kix_list_15-2 > li:before {
  content: '' counter(lst-ctn-kix_list_15-2, lower-roman) '. ';
}
.lst-kix_list_10-8 > li {
  counter-increment: lst-ctn-kix_list_10-8;
}
.lst-kix_list_10-6 > li:before {
  content: '' counter(lst-ctn-kix_list_10-6, decimal) '. ';
}
.lst-kix_list_9-1 > li:before {
  content: '' counter(lst-ctn-kix_list_9-1, lower-latin) '. ';
}
ol.lst-kix_list_12-7.start {
  counter-reset: lst-ctn-kix_list_12-7 0;
}
.lst-kix_list_15-8 > li {
  counter-increment: lst-ctn-kix_list_15-8;
}
ol.lst-kix_list_12-8.start {
  counter-reset: lst-ctn-kix_list_12-8 0;
}
.lst-kix_list_20-3 > li:before {
  content: '' counter(lst-ctn-kix_list_20-3, decimal) '. ';
}
.lst-kix_list_10-1 > li {
  counter-increment: lst-ctn-kix_list_10-1;
}
.lst-kix_list_8-8 > li {
  counter-increment: lst-ctn-kix_list_8-8;
}
ol.lst-kix_list_17-6.start {
  counter-reset: lst-ctn-kix_list_17-6 0;
}
.lst-kix_list_1-6 > li:before {
  content: '\002022   ';
}
.lst-kix_list_12-6 > li:before {
  content: '' counter(lst-ctn-kix_list_12-6, decimal) '. ';
}
.lst-kix_list_2-2 > li:before {
  content: '\002022   ';
}
.lst-kix_list_13-2 > li:before {
  content: '' counter(lst-ctn-kix_list_13-2, lower-roman) '. ';
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c35 {
  margin-left: 3.8pt;
  padding-top: 0pt;
  padding-left: -3.8pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  text-align: justify;
  margin-right: 0.1pt;
}
.c85 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c29 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: -3.8pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  text-align: justify;
  margin-right: 5.9pt;
}
.c84 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c62 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 7pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c36 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: -3.8pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  text-align: justify;
  margin-right: 0.1pt;
}
.c18 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 7pt;
  font-family: 'Belleza';
  font-style: normal;
}
.c9 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 7pt;
  font-family: 'Belleza';
  font-style: italic;
}
.c86 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c23 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: -3.8pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  text-align: justify;
  margin-right: 5.8pt;
}
.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 7pt;
  font-family: 'Belleza';
  font-style: normal;
}
.c105 {
  margin-left: 34.6pt;
  padding-top: 3pt;
  padding-left: -6.7pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
  margin-right: 2.5pt;
}
.c52 {
  margin-left: 24.2pt;
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1;
  padding-left: -10.7pt;
  text-align: justify;
}
.c43 {
  margin-left: 34.6pt;
  padding-top: 2.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  padding-left: -6.7pt;
  text-align: justify;
}
.c92 {
  margin-left: 35.6pt;
  padding-top: 3pt;
  padding-bottom: 0pt;
  line-height: 1;
  padding-left: -6.7pt;
  text-align: justify;
}
.c28 {
  margin-left: 32.2pt;
  padding-top: 5.9pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  padding-left: -9.4pt;
  text-align: justify;
}
.c26 {
  margin-left: 24.2pt;
  padding-top: 5.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  padding-left: -10.6pt;
  text-align: justify;
}
.c49 {
  margin-left: 5.2pt;
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
  margin-right: 5.8pt;
}
.c17 {
  margin-left: 24.2pt;
  padding-top: 5.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  padding-left: -10.6pt;
  text-align: justify;
}
.c13 {
  margin-left: 6.2pt;
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
  height: 11pt;
}
.c58 {
  margin-left: 25.1pt;
  padding-top: 2.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  padding-left: 3.2pt;
  text-align: justify;
}
.c2 {
  margin-left: 5.2pt;
  padding-top: 5.7pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
  margin-right: 5.9pt;
}
.c82 {
  margin-left: 35.5pt;
  padding-top: 2.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  padding-left: -6.6pt;
  text-align: justify;
}
.c3 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  padding-left: -3.8pt;
  text-align: justify;
}
.c27 {
  margin-left: 10.9pt;
  padding-top: 5.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  padding-left: 3.3pt;
  text-align: justify;
}
.c66 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
  margin-right: 5.9pt;
}
.c61 {
  margin-left: -14.2pt;
  padding-top: 8.1pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 1.4pt;
}
.c111 {
  margin-left: 23.2pt;
  padding-top: 5.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  padding-left: -9.6pt;
  text-align: justify;
}
.c110 {
  margin-left: 34.6pt;
  padding-top: 3pt;
  padding-bottom: 0pt;
  line-height: 1;
  padding-left: -6.7pt;
  text-align: justify;
}
.c5 {
  margin-left: 18pt;
  padding-top: 4pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  padding-left: -3.8pt;
  text-align: justify;
}
.c69 {
  margin-left: 35.5pt;
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  padding-left: -6.6pt;
  text-align: justify;
}
.c16 {
  margin-left: 5.2pt;
  padding-top: 5.9pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-indent: -0pt;
  text-align: justify;
}
.c87 {
  margin-left: -28.4pt;
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c55 {
  margin-left: 5.2pt;
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
}
.c77 {
  margin-left: 5.2pt;
  padding-top: 5.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c65 {
  margin-left: 6.2pt;
  padding-top: 7.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c19 {
  margin-left: 7.1pt;
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
}
.c54 {
  margin-left: -14.2pt;
  padding-top: 0.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c45 {
  margin-left: 6.2pt;
  padding-top: 5.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c8 {
  margin-left: 6.2pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c21 {
  margin-left: 7.1pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c33 {
  margin-left: 6.2pt;
  padding-top: 5.7pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
}
.c90 {
  padding-top: 5.7pt;
  padding-bottom: 0pt;
  line-height: 1.75;
  text-align: justify;
  margin-right: 122pt;
}
.c96 {
  margin-left: 28.9pt;
  padding-top: 2.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c56 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-style: normal;
}
.c31 {
  margin-left: -14.2pt;
  padding-top: 0.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c38 {
  margin-left: -14.2pt;
  padding-top: 5.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c88 {
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 6pt;
}
.c67 {
  margin-left: 6.2pt;
  padding-top: 5.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c11 {
  margin-left: -14.2pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c75 {
  padding-top: 8.1pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 1.4pt;
}
.c6 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  height: 11pt;
}
.c51 {
  margin-left: -14.2pt;
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c103 {
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
  margin-right: 5.9pt;
}
.c0 {
  margin-left: -7.1pt;
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -16.9pt;
}
.c20 {
  margin-left: 6.2pt;
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
}
.c39 {
  margin-left: 7.1pt;
  padding-top: 2.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c10 {
  margin-left: -14.2pt;
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c7 {
  margin-left: 5.2pt;
  padding-top: 5.7pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
}
.c30 {
  padding-top: 5.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: -27.3pt;
}
.c72 {
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c101 {
  padding-top: 2.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c24 {
  font-size: 7pt;
  font-family: 'Belleza';
  color: #000000;
  font-weight: 700;
}
.c71 {
  padding-top: 5.7pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
}
.c112 {
  padding-top: 4pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  text-align: justify;
}
.c42 {
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  text-align: justify;
}
.c60 {
  padding-top: 0.5pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  text-align: justify;
}
.c107 {
  padding-top: 0.4pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  text-align: justify;
}
.c74 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
}
.c15 {
  font-size: 7pt;
  font-family: 'Belleza';
  color: #000000;
  font-weight: 400;
}
.c91 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: center;
}
.c25 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c94 {
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c46 {
  padding-top: 5.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c109 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c106 {
  padding-top: 0.5pt;
  padding-bottom: 0.1pt;
  line-height: 1;
  text-align: center;
}
.c98 {
  padding-top: 0.3pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c37 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  text-align: justify;
}
.c70 {
  padding-top: 5.9pt;
  padding-bottom: 0pt;
  line-height: 1.0291666666666666;
  text-align: justify;
}
.c32 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: italic;
}
.c41 {
  background-color: #ffffff;
  max-width: 451.5pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c99 {
  text-decoration: none;
  vertical-align: super;
  font-style: normal;
}
.c76 {
  font-size: 7pt;
  font-weight: 400;
  font-family: 'Belleza';
}
.c64 {
  margin-left: 5.2pt;
  list-style-position: inside;
  text-indent: 45pt;
}
.c12 {
  padding: 0;
  margin: 0;
}
.c113 {
  margin-left: 3.8pt;
  padding-left: 17.5pt;
}
.c73 {
  margin-left: -10.4pt;
  padding-left: -3.8pt;
}
.c83 {
  margin-left: 18pt;
  padding-left: -3.8pt;
}
.c44 {
  margin-left: 3.8pt;
  padding-left: -3.8pt;
}
.c40 {
  margin-left: 18pt;
  padding-left: 3.3pt;
}
.c104 {
  margin-left: 38pt;
  padding-left: 0pt;
}
.c89 {
  list-style-position: inside;
  text-indent: 45pt;
}
.c100 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.c47 {
  margin-left: 21.3pt;
  margin-right: -27.3pt;
}
.c48 {
  margin-left: 24.2pt;
  padding-left: -2.9pt;
}
.c63 {
  margin-left: 24.2pt;
  padding-left: -10.7pt;
}
.c95 {
  margin-left: 23.2pt;
  padding-left: -8.8pt;
}
.c59 {
  margin-left: 32.2pt;
  padding-left: -3.9pt;
}
.c68 {
  margin-left: 24.2pt;
  padding-left: -10pt;
}
.c78 {
  margin-left: 6.2pt;
  text-indent: -0pt;
}
.c93 {
  margin-right: 6.9pt;
}
.c80 {
  margin-right: 0.1pt;
}
.c108 {
  margin-left: -14.2pt;
}
.c97 {
  margin-left: 14.2pt;
}
.c34 {
  margin-right: 6.8pt;
}
.c53 {
  margin-right: -31.1pt;
}
.c50 {
  margin-left: 7.1pt;
}
.c22 {
  height: 11pt;
}
.c102 {
  margin-right: -13.2pt;
}
.c81 {
  text-indent: -14.2pt;
}
.c57 {
  margin-right: 5.8pt;
}
.c79 {
  margin-left: 5.2pt;
}
.c14 {
  margin-right: -16.9pt;
}
// .title {
//   padding-top: 24pt;
//   color: #000000;
//   font-weight: 700;
//   font-size: 36pt;
//   padding-bottom: 6pt;
//   font-family: 'Calibri';
//   line-height: 1;
//   page-break-after: avoid;
//   text-align: left;
// }
// .subtitle {
//   padding-top: 18pt;
//   color: #666666;
//   font-size: 24pt;
//   padding-bottom: 4pt;
//   font-family: 'Georgia';
//   line-height: 1;
//   page-break-after: avoid;
//   font-style: italic;
//   text-align: left;
// }
// li {
//   color: #000000;
//   font-size: 11pt;
//   font-family: 'Calibri';
// }
// p {
//   margin: 0;
//   color: #000000;
//   font-size: 11pt;
//   font-family: 'Calibri';
// }
// h1 {
//   padding-top: 8.1pt;
//   color: #000000;
//   font-size: 14pt;
//   padding-bottom: 0pt;
//   font-family: 'Calibri';
//   line-height: 1;
//   text-align: left;
// }
// h2 {
//   padding-top: 0pt;
//   color: #000000;
//   font-size: 13.5pt;
//   padding-bottom: 0pt;
//   font-family: 'Calibri';
//   line-height: 1;
//   text-align: left;
// }
// h3 {
//   padding-top: 0pt;
//   color: #000000;
//   font-size: 10.5pt;
//   padding-bottom: 0pt;
//   font-family: 'Calibri';
//   line-height: 1;
//   text-align: justify;
// }
// h4 {
//   padding-top: 0pt;
//   color: #000000;
//   font-weight: 700;
//   font-size: 10pt;
//   padding-bottom: 0pt;
//   font-family: 'Calibri';
//   line-height: 1;
//   text-align: justify;
// }
// h5 {
//   padding-top: 0pt;
//   color: #000000;
//   font-weight: 700;
//   font-size: 9.5pt;
//   padding-bottom: 0pt;
//   font-family: 'Calibri';
//   line-height: 1;
//   text-align: left;
// }
// h6 {
//   padding-top: 10pt;
//   color: #000000;
//   font-weight: 700;
//   font-size: 10pt;
//   padding-bottom: 2pt;
//   font-family: 'Calibri';
//   line-height: 1;
//   page-break-after: avoid;
//   text-align: left;
// }
