@use '../../global-styles/colors';
@use '../../global-styles/mixins';
@use '../../global-styles/breakpoints';

.faq-card {
  width: 44%;
  max-height: 236px;
  background: #ffffff;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  padding: 32px;
  &-icon {
    margin-bottom: 44px;
  }
  &:nth-child(2) {
    @media (max-width: breakpoints.$breakpoint-tablet) {
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }
  &:hover {
    border: 1px solid colors.$light-gray-alpha;
  }
  svg {
    width: 40px;
    height: 36px;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 100%;
  }
}
.faq-container {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
  }
}
.breadcrumbs-arrow {
  margin-left: 8px;
  margin-right: 8px;
}
.faq-details-icon {
  margin-right: 16px;
}
.faq-details-heading {
  flex: 1;
  font-size: 48px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-bottom: 0px;
    margin-top: 16px;
  }
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid colors.$light-gray-alpha;
  background: #ffffff;
  height: 50px;
  color: colors.$dark-gray-alpha;
  padding: 16px;
  @include mixins.font(700);
  font-size: 16px;
  line-height: 130%;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    padding-left: 0px;
  }
}

.accordion-title:hover {
  border-bottom: 2px solid colors.$light-gray-alpha;
}

.accordion-content {
  padding: 16px;
  @include mixins.font(400);
  background-color: #ffff;
  color: colors.$dark-gray-alpha;
  font-size: 14px;
  line-height: 155%;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    padding-left: 0px;
  }
  & ul {
    margin: 15px;
    margin-right: 0px;
  }
}

.accordion-anchor {
  font-size: 14px;
}

.accordion-icon {
  width: 48px;
  height: 48px;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  text-align: center;
  line-height: 48px;
}

.faq-accordion {
  flex: 2;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-left: 0px;
  }
}
.faq-side-navigation {
  flex: 0.5;
  margin-right: 24px;
  gap: 12px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.faq-content-wrapper {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
  }
}
.selected {
  margin-left: 8px;
  svg {
    width: 40px;
    height: 36px;
  }
}
.faq-navigation-list {
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    min-width: 200px;
    display: flex;
    justify-content: flex-start;
  }
}

.faq-cards-container {
  flex-wrap: wrap;
  row-gap: 25px;
  justify-content: space-between;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
  }
}
.faq-selected-details {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    justify-content: left;
    align-items: left;
  }
}
.faq-card-icon {
  width: 40px;
  height: 36px;
}
.faq-card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  cursor: pointer;
  color: colors.$dark-gray-bravo;
}
.faq-card-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: colors.$dark-gray-alpha;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    text-align: center;
  }
}
.faq-card-wrapper {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    justify-content: center;
    align-items: center;
  }
}
.faq-sub-heading {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    text-align: center;
  }
}
.faq-selected-tab {
  font-weight: 700;
  color: colors.$dark-gray-alpha;
}
.faq-selected-container {
  background-color: rgba(226, 226, 226, 0.5);
  border-radius: 8px;
  align-items: center;
  min-width: 200px;
}
.faq-selected-icon {
  margin-left: 12px;
  margin-right: 16px;
}
.faq-selected-option-title {
  font-size: 16px;
  font-weight: 700;
  color: colors.$dark-gray-bravo;
}
.faq-list-item {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: colors.$dark-gray-alpha;
}
.faq-navigation {
  align-items: center;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    visibility: hidden;
  }
}
