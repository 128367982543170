@use '../../global-styles/colors';
@use '../../global-styles/mixins';
@use '../../global-styles/breakpoints';

.help-center-main-layout {
  margin: 0 70px;
  text-align: center;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin: 0;
  }

  &__heading {
    @media (max-width: breakpoints.$breakpoint-tablet) {
      text-align: center;
    }
  }

  &__search-input {
    margin: 0px 150px 100px;

    @media (max-width: breakpoints.$breakpoint-tablet) {
      margin: 0px 10px;
    }

    input {
      border-radius: 40px;
      padding-left: 30px;
      padding-right: 30px;
    }

    &-icon {
      position: absolute;
      margin-left: -40px;
      margin-top: 10px;
    }
  }
  &__featured-articles {
    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 50px;
    }
    &-item {
      width: 350px;
      text-align: left;

      &-heading {
        text-decoration: none;
        color: colors.$dark-gray-bravo;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__search-results {
    &-heading {
      text-align: left;
    }

    &-item {
      border-bottom: 1px solid colors.$light-gray-echo;
      padding-bottom: 16px;
      text-align: left;

      &-heading {
        text-decoration: none;
        color: colors.$dark-gray-bravo;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: 0;

        &:hover {
          text-decoration: underline;
        }
      }

      & .learn-more {
        color: colors.$blue-charlie;
        text-decoration: none;
        font-weight: 500;
      }
    }
  }
}

.help-center-article-main-layout {
  margin: 0 70px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin: 0 20px;
  }

  &__content {
    & > h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    blockquote,
    li {
      color: colors.$dark-gray-alpha;
    }
  }
}

.contact-us-form-error-message-wrapper {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.field-container {
  width: 100%;
}

.breadcrumb-content-wrapper {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    display: none;
  }
}

.breadcrumb-navigation {
  align-items: center;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    visibility: hidden;
  }
  & span {
    &:hover {
      color: colors.$black;
    }
  }
}

.breadcrumb-selected-tab {
  color: colors.$dark-gray-alpha;
  font-weight: 700;
}
