@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

.input-field {
  @include mixins.font();
  padding: 17px 16px;
  border: 1px solid colors.$light-gray-delta;
  border-radius: 8px;
  background-color: colors.$white;
  width: 100%;
  box-sizing: border-box;
  font-style: normal;
  font-size: 14px;
  line-height: 120%;
  color: colors.$light-gray-echo;
  &:hover {
    border: 1px solid colors.$dark-gray-alpha;
  }

  &:focus-visible {
    outline: none !important;
    color: colors.$dark-gray-bravo;
    border: 1px solid colors.$dark-gray-alpha;
  }
}

.success-input {
  border: 1px solid colors.$success;
}

.error-input {
  border: 1px solid colors.$error;
}

.input-label {
  @include mixins.font(700);
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 140%;
  color: colors.$black;
}

.input-icon {
  margin-left: -35px;
  position: absolute;
  margin-top: 20px;
}

.input-checkbox-container {
  display: flex;
  align-items: center;
  & .input-checkbox {
    min-height: 22px;
    min-width: 22px;
    max-height: 22px;
    max-width: 22px;
    accent-color: colors.$blue-charlie;
  }
  & .input-checkbox-label {
    margin-left: 16px;
    @include mixins.font(400);
    font-size: 14px;
    line-height: 140%;
    color: colors.$dark-gray-alpha;
  }
}

.radio-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  div {
    display: flex;
    gap: 10px;
  }
}
