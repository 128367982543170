@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';
@use '../../../global-styles/breakpoints';

.project-card-container {
  @include mixins.font();
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid colors.$light-gray-alpha;
  background-color: colors.$white;
  width: 412px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  height: 825px;
  position: relative;
  &-wrapper {
    padding: 25px 32px 32px 32px;
  }
  &:hover {
    background-color: colors.$blue-charlie;
    color: colors.$white;
    .closed-for-investment {
      color: colors.$white;
    }
    .tag-container {
      background-color: colors.$white;
      color: colors.$black;
    }
    .card-main,
    .card-sub {
      color: colors.$white;
    }
    .title {
      color: colors.$white;
    }
    .title-container {
      color: colors.$white;
    }
    .description-container > p {
      color: colors.$white;
    }
  }
  &:focus {
    outline: none;
    background-color: colors.$blue-charlie;
    box-shadow: 0px 0px 0px 8px colors.$red-echo;
    color: colors.$white;
    .closed-for-investment {
      color: colors.$white;
    }
    .tag-container {
      background-color: colors.$white;
      color: colors.$black;
    }
    .card-main,
    .card-sub {
      color: colors.$white;
    }
    .title {
      color: colors.$white;
    }
  }
  @media (max-width: breakpoints.$breakpoint-mobile) {
    width: 92vw;
  }
}

.p-2 {
  padding: 2rem;
}

.tags {
  display: flex;
  gap: 10px;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.title-container {
  display: flex;
  flex-direction: column;
  .title {
    transition: all 200ms ease-in-out;
    font-size: 24px;
    font-weight: 700;
    color: colors.$dark-gray-bravo;
    line-height: 110%;
    &:hover {
      color: colors.$white;
    }
  }
  &.right {
    font-weight: 700;
    font-size: 14px;
    text-align: right;
    color: colors.$dark-gray-bravo;
    line-height: 120%;
    span {
      font-size: 16px;
    }
  }
}

.closed-for-investment {
  color: colors.$blue-charlie;
}

.description-container {
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: colors.$dark-gray-alpha;
  }
  @media (max-width: breakpoints.$breakpoint-mobile) {
    text-align: center;
  }
}

.stats-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  width: 158px;
  height: 105px;
  .card-main {
    transition: all 200ms ease-in-out;
    font-size: 32px;
    font-weight: 700;
    line-height: 100%;
    color: colors.$dark-gray-bravo;
  }
  .card-sub {
    transition: all 200ms ease-in-out;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    color: colors.$dark-gray-alpha;
  }
}

.stats-container {
  display: flex;
  gap: 32px;
}

.lower-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  height: 100%;
}

.project-h-container {
  @include mixins.font(700);
  display: flex;
  align-items: center;
  height: 220px;
  width: 506px;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
  .project-h-image {
    padding: 32px 0 32px 32px;
    height: 100%;
    img {
      margin-top: 35px;
      width: 113px;
      height: 113px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .project-h-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
  }
  .project-h-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: colors.$dark-gray-alpha;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  .project-h-stats {
    display: flex;
    gap: 32px;
    color: colors.$dark-gray-bravo;
    p {
      font-size: 14px;
      font-weight: 400;
    }
    strong {
      font-size: 16px;
      font-weight: 700;
    }
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    max-width: 85%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: fit-content;
    .project-h-image {
      padding: 12px;
      margin: 0;
    }
    .project-h-content {
      padding: 12px;
      align-items: center;
    }
  }
}
