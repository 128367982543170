@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

@mixin status-variant($fontWeight, $fontSize: 12px, $color) {
  @include mixins.font($fontWeight);
  font-size: $fontSize;
  line-height: 140%;
  color: $color;
}

.success {
  &-thin-label {
    @include status-variant(400, 12px, colors.$success);
  }
  &-basic-label {
    @include status-variant(500, 12px, colors.$success);
  }
  &-bold-label {
    @include status-variant(700, 14px, colors.$success);
  }
}

.info {
  &-thin-label {
    @include status-variant(400, 12px, colors.$light-gray-echo);
  }
  &-basic-label {
    @include status-variant(500, 12px, colors.$light-gray-echo);
  }
  &-bold-label {
    @include status-variant(700, 14px, colors.$light-gray-echo);
  }
}

.error {
  &-thin-label {
    @include status-variant(400, 12px, colors.$error);
  }
  &-basic-label {
    @include status-variant(500, 12px, colors.$error);
  }
  &-bold-label {
    @include status-variant(700, 14px, colors.$error);
  }
}

.warning {
  &-thin-label {
    @include status-variant(400, 12px, colors.$orange-alpha);
  }
  &-basic-label {
    @include status-variant(500, 12px, colors.$orange-alpha);
  }
  &-bold-label {
    @include status-variant(700, 14px, colors.$orange-alpha);
  }
}

.progress {
  &-thin-label {
    @include status-variant(400, 12px, colors.$blue-alpha);
  }
  &-basic-label {
    @include status-variant(500, 12px, colors.$blue-alpha);
  }
  &-bold-label {
    @include status-variant(700, 14px, colors.$blue-alpha);
  }
}

.vertical-center {
  display: flex;
  align-items: center;
}
.margin-left {
  margin-left: 5px;
}
