@use '../../global-styles/colors';

.terms-and-conditions-container {
  h2 {
    font-size: 30px !important;
    margin: 20px 0;
    color: #03041c;
    line-height: 1.35;
    font-weight: 700;
    font-family: 'Inter Tight', sans-serif;
  }
  h2.nomargin {
    font-size: 30px !important;
    margin: 40px 0 10px !important;
  }
  p,
  li {
    font-size: 18px !important;
    text-align: justify;
    color: #6a6c71;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.6;
  }
  li {
    margin-left: 40px;
  }
  .ptb {
    margin-top: 20px;
    color: #03041c;
  }
  a.btn {
    border: 1px solid colors.$blue-charlie;
    color: colors.$blue-charlie !important;
    padding: 5px 25px;
    border-radius: 38px;
  }
  a.btn:hover {
    background: colors.$blue-charlie;
    color: #fff !important;
    padding: 5px 25px;
    border-radius: 38px;
  }
  a.link {
    color: colors.$blue-delta !important;
  }
}
