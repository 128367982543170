@use '../../../global-styles/colors';

.success {
  fill: colors.$success;
}
.warning {
  fill: colors.$orange-alpha;
}
.progress {
  fill: colors.$blue-alpha;
}
.error {
  fill: colors.$error;
}
.default {
  fill: colors.$dark-gray-bravo;
}
.red {
  fill: colors.$blue-charlie;
}

.default-light {
  fill: colors.$light-gray-echo;
}
.warning-banner {
  stroke: colors.$orange-alpha;
}
.error-banner {
  stroke: colors.$blue-charlie;
}
.success-banner {
  stroke: colors.$green-alpha;
}

.rotate-right-90 {
  transform: rotate(90deg);
}

.rotate-left-90 {
  transform: rotate(-90deg);
}
