@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

.signature-pad {
  background: #ffffff;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
}
#canvas {
  width: 300px;
  height: 300px;
}
