@use '../../../global-styles/colors';

.flex {
  display: flex;
  &.column-center {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  &.row-center {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.space-evenly {
    justify-content: space-evenly;
    width: 100%;
  }
  &.column {
    flex-direction: column;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.no-wrap {
    flex-wrap: nowrap;
  }
  &.flex-end {
    align-items: flex-end;
  }
  &.align-center {
    align-items: center;
  }
  &.justify-center {
    justify-content: center;
  }
  &.row-reverse {
    flex-direction: row-reverse;
  }
  &.column-reverse {
    flex-direction: column-reverse;
  }
  &.justify-end {
    justify-content: flex-end;
  }
}

.overflow-y-hidden {
  overflow-y: hidden;
}
