@use '../../global-styles/colors';
@use '../../global-styles/mixins';
@use '../../global-styles/breakpoints';

.dashboard-top-section-wrapper {
  & > h2 {
    letter-spacing: -0.02em;
  }
}

.dashboard-top-section {
  &-container {
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    padding: 48px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
      flex-direction: column;
    }
  }
  &-left {
    display: flex;
    flex-direction: column;
    width: 35%;
    @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
      text-align: center;
      width: auto;
      overflow-wrap: break-word;
    }
    & > .basic-anchor {
      font-size: 14px;
    }
    & > .thin {
      font-size: 14px;
    }
    & > .investment-container {
      margin-top: 43px;
      margin-bottom: 9px;
    }
    & .investment-limit {
      font-size: 12px;
      line-height: 140%;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    & .investment-limit-values {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #2c3941;
    }
  }
  &-right {
    display: flex;
    justify-content: space-between;
    width: 40%;
    column-gap: 110px;
    margin-top: 40px;
    @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
      width: auto;
      flex-direction: column;
      align-items: center;
    }
    &-status-container {
      display: flex;
      flex-direction: column;
      width: max-content;
      & > a {
        font-size: 14px;
      }
      @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
        flex-direction: row;
        column-gap: 14px;
        align-items: center;
        justify-content: center;
        & > span {
          margin-top: 0;
        }
        & > a {
          margin-top: 0;
        }
      }
    }
    & span {
      font-size: 14px;
    }
    & p {
      color: #545a5e;
    }
  }
}

.no-project-found-wrapper {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  height: 460px;
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  & > span {
    font-weight: 800;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: #2c3941;
    margin-top: 24px;
    margin-bottom: 12px;
  }
}

.dashboard-info-card {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  width: 307px;
  height: 164px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;
  align-items: center;
  @media (max-width: breakpoints.$breakpoint-large-screen) {
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
  }

  &-heading {
    font-weight: 700;
    font-size: 40px;
    line-height: 110%;
    color: #2c3941;
  }
  &-sub-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #545a5e;
  }

  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 42px;
    margin-bottom: 21px;
    flex-wrap: wrap;
    row-gap: 32px;
  }
}

.filter-metrics {
  padding: 8px 20px;
  align-items: center;
  gap: 12px;
  text-align: center;

  p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #545a5e;
  }
  span {
    /* Headings/h5 */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    /* identical to box height, or 28px */

    text-align: center;

    color: #2c3941;
  }
}

.dashboard-toolbar-layout {
  width: auto !important;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 8px 32px;
}

.dashboard-pagination-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.quarterly-report-text {
  color: #247cff;
  font-size: 30px;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
