@use '../../../global-styles/breakpoints';

.popup-blocked {
  background-color: white;

  padding: 40px;
  width: 30%;
  border-radius: 10px;
  line-height: 1.5;

  ol {
    margin-left: 25px;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 60%;
  }

  @media (max-width: breakpoints.$breakpoint-mobile) {
    width: 80%;
  }
}
