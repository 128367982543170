@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

.modal-container {
  height: 100vh;
  width: 100%;
  background-color: rgba($color: colors.$black, $alpha: 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: none;
  justify-content: center;
  align-items: center;
  display: flex;
}
.input-modal {
  width: 413px;
  height: fit-content;
  background-color: colors.$white;
  border-radius: 8px;
  border: 1px solid colors.$light-gray-alpha;
  padding: 24px 32px;
}

.reply-section {
  gap: 24px;
}
