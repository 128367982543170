@use '../../../global-styles/colors';

.line {
  width: 100%;
  height: 0;
  border: 0.5px solid colors.$light-gray-alpha;
  margin: 24px 0px;
}

.border-1 {
  border: 1px solid colors.$light-gray-alpha;
}

.border-2 {
  border: 0.5px solid colors.$light-gray-lima;
  margin-bottom: 14px;
}
