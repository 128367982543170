@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';

.input-file {
  display: none;
  &-main-container {
    display: flex;
    max-width: 491px;
    flex-direction: column;
    min-height: fit-content;
    border-left: 8px solid colors.$light-gray-alpha;
    padding-left: 24px;
    & .label-text {
      @include mixins.font(600);
      font-size: 18px;
      line-height: 150%;
      color: colors.$dark-gray-bravo;
      margin-bottom: 12px;
    }
  }
  &-container {
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
    color: colors.$dark-gray-alpha;
    height: 68px;
    background-color: colors.$light-gray-foxtrot;
    border: 1px dashed colors.$dark-gray-alpha;
    border-radius: 6px;
  }
  &-label-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 25px;
    overflow: hidden;

    li {
      &:not(:first-child) {
        margin-top: 15px;
      }
      &:last-child {
        margin-bottom: 15px;
      }
      &-label {
        align-items: center;
      }
      .file-list {
        align-items: center;
      }
    }
  }
  &-label {
    @include mixins.font(700);
    font-size: 14px;
    line-height: 100%;
  }
  &-sub-label {
    @include mixins.font(400);
    font-size: 14px;
    line-height: 100%;
  }
}

.success {
  color: colors.$success;
}

.pending {
  color: colors.$orange-alpha;
}

.error {
  color: colors.$error;
}
.success-border {
  border-left: 8px solid colors.$success;
}
.pending-border {
  border-left: 8px solid colors.$orange-alpha;
}
.error-border {
  border-left: 8px solid colors.$error;
}

.comment {
  font-size: 14px;
  font-weight: 400;
}

.placeholder-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
  font-size: 14px;
  font-weight: 400;
  color: colors.$light-gray-echo;
  margin-bottom: 10px;
}

.info-icon {
  transform: rotate(180deg);
}

.selected-file {
  border-style: solid;
  border-color: colors.$light-gray-alpha;
}
.input-file-delete-icon {
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}

.multi {
  height: auto;
  padding: 15px 25px;
}

.file-list {
  display: flex;
  gap: 8px;
  justify-content: space-between;

  span {
    width: 90%;
    word-break: break-all;
  }

  svg {
    cursor: pointer;
  }
}
