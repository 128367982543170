@use '../../global-styles/breakpoints';
@use '../../global-styles/colors';
@use '../../global-styles/mixins';

.payment {
  &-upload-doc-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: breakpoints.$breakpoint-tablet) {
      flex-direction: column;
      align-items: center;
    }
    & > h2 {
      letter-spacing: -0.02em;
    }
    & span {
      font-size: 14px;
      line-height: 120%;
      margin-right: 8px;
      color: colors.$dark-gray-alpha;
    }
  }
  &-upload-doc-user-wrapper {
    display: flex;
    gap: 33px;
    @media (max-width: breakpoints.$breakpoint-large-screen) {
      flex-direction: column;
      & .payment-company-card {
        max-width: 411px;
      }
    }
    &-left {
      flex-grow: 1;
      & > .payment-button-container {
        margin-top: 24px;
        display: flex;
        gap: 20px;
        & button {
          padding: 17px 32px;
          width: auto;
          @media (max-width: breakpoints.$breakpoint-tablet) {
            width: 100%;
          }
        }
      }
      & > .acknowledgement-container {
        margin-top: 48px;
        @media (max-width: breakpoints.$breakpoint-tablet) {
          text-align: center;
        }
        & > .input-file-main-container {
          border-left: none;
          padding-left: 0px;
          max-width: 100%;
          margin-top: 30px;
          & > .input-file-container {
            height: 147px;
            justify-content: center;
            width: 100%;
            padding: 0px;

            & > .input-file-label-container {
              flex-grow: unset;
            }

            & > .input-file-delete-icon {
              margin-left: 8px;
            }
          }
          & > .label-text {
            display: none;
          }
        }
        & > .sub-heading {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #000000;
        }
        & > h4 {
          line-height: 110%;
          margin-bottom: 16px;
        }
      }
    }
    &-right {
      flex-grow: 0.2;
      & > .payment-company-card {
        min-width: 250px;
        background: #f9f9f9;
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        padding: 32px;
        margin-top: 42px;
        @media (max-width: breakpoints.$breakpoint-large-screen) {
          margin-top: 0;
        }
        & > .line {
          margin: 33px 0px;
        }
        & .company-info-container {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #000000;
          display: flex;
          flex-direction: column;
          gap: 15px;
          margin-top: 60px;
          margin-bottom: 11px;
        }
        & > .summary-label {
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          color: #545a5e;
        }
        & > .company-image-container {
          margin-top: 24px;
          display: flex;
          align-items: center;
          margin-bottom: 41px;
          gap: 16px;
          & > img {
            height: 40px;
            width: 40px;
          }
          & > .company-name {
            font-weight: 700;
            font-size: 32px;
            line-height: 110%;
            color: #2c3941;
          }
        }
        & > .property-container {
          display: flex;
          flex-direction: column;
          row-gap: 18px;
          & .property-row {
            display: flex;
            justify-content: space-between;
            line-height: 120%;
            & .property-column-left {
              font-weight: 400;
              font-size: 14px;
              color: #545a5e;
            }
            & .property-column-right {
              font-weight: 700;
              font-size: 16px;
              color: #2c3941;
            }
          }
        }
      }
    }
  }
  &-upload-doc-user-container {
    background: colors.$white;
    display: flex;
    overflow: auto;

    table {
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: #545a5e;
    }

    &-left {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      margin-left: 8px;
      & > .user-name {
        line-height: 120%;
      }
      & > .user-email {
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: colors.$dark-gray-alpha;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      @media (max-width: breakpoints.$breakpoint-tablet) {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
      }
      & span {
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #545a5e;
      }
    }
  }
}

.font-bold {
  font-weight: 1000 !important;
  font-size: 24px !important;
}

.payment-submit-successful {
  &-wrapper {
    margin-top: 96px;
    text-align: center;
    & h2 {
      letter-spacing: -0.02em;
      margin-top: 30px;
      margin-bottom: 21px;
    }
    & h4 {
      width: 40%;
      margin-bottom: 29px;
      @media (max-width: breakpoints.$breakpoint-tablet) {
        width: 100%;
      }
    }
    & p {
      text-align: center;
    }
  }
}

.payment-warning-container {
  background: rgba(242, 163, 45, 0.1);
  border-radius: 8px;
  color: #f2a32d;
  max-width: 468px;
  padding: 16px 12px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;

  &-full {
    @extend .payment-warning-container;
    max-width: 50%;
    @media (max-width: breakpoints.$breakpoint-large-screen) {
      max-width: 100%;
    }
  }
  &-fit-content {
    @extend .payment-warning-container;
    min-width: fit-content;
    @media (max-width: breakpoints.$breakpoint-large-screen) {
      max-width: 100%;
    }
  }
}

.payment-success-container {
  background: colors.$green-bravo;
  border-radius: 8px;
  color: colors.$green-alpha;
  max-width: 468px;
  padding: 16px 12px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
}

.payment {
  &-summary-heading-container {
    display: flex;
    // justify-content: space-between;
    column-gap: 40px;
    align-items: flex-end;
    margin-bottom: 32px;
    @media (max-width: breakpoints.$breakpoint-tablet) {
      flex-direction: column;
      align-items: center;
    }
    & > h2 {
      letter-spacing: -0.02em;
    }
    & span {
      font-size: 14px;
      line-height: 120%;
      margin-right: 8px;
      color: colors.$dark-gray-alpha;
    }
  }
  &-summary-wrapper {
    display: flex;
    gap: 33px;
    @media (max-width: breakpoints.$breakpoint-large-screen) {
      flex-direction: column;
    }
    &-left {
      width: 71%;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      border-radius: 8px;
      padding: 32px;

      & > .button-container {
        display: flex;
        gap: 12px;
        margin-top: 48px;
        margin-bottom: 2px;
        & button {
          width: auto;
          padding: 16px 24px;
        }
        @media (max-width: breakpoints.$breakpoint-tablet) {
          flex-direction: column;
          align-items: center;
          & button {
            width: 100%;
          }
        }
      }
      & > span {
        font-weight: 700;
        font-size: 16px;
        line-height: 110%;
        color: #545a5e;
      }
      & .further-instruction-container {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin: 32px 0px;
        & .further-instruction-row {
          display: flex;
          column-gap: 32px;
          @media (max-width: breakpoints.$breakpoint-tablet) {
            flex-direction: column;
            align-items: center;
            row-gap: 24px;
          }
          & .further-instruction-column-left {
            width: 58px;
            height: 58px;
            background: #ffffff;
            border: 1px solid #e2e2e2;
            border-radius: 8px;
            font-weight: 700;
            font-size: 24px;
            line-height: 110%;
            text-align: center;
            color: #2c3941;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          & .further-instruction-column-right {
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            width: 85%;
            & a {
              font-size: 24px;
            }
            @media (max-width: breakpoints.$breakpoint-tablet) {
              width: 100%;
              text-align: center;
              & p {
                text-align: center;
              }
            }
          }
        }
      }
      @media (max-width: breakpoints.$breakpoint-large-screen) {
        width: auto;
      }
      @media (max-width: breakpoints.$breakpoint-tablet) {
        text-align: center;
      }
    }
    &-right {
      min-width: 30%;
      max-width: 35%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      & > .payment-company-card {
        width: -webkit-fill-available;
        background: #f9f9f9;
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        padding: 32px;
        & > .line {
          margin: 33px 0px;
        }
        & .company-info-container {
          display: flex;
          flex-direction: column;
          gap: 15px;
          margin-top: 60px;
          margin-bottom: 11px;
          & .input-checkbox-label {
            color: #2c3941;
          }
        }
        & > .summary-label {
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          color: #545a5e;
        }
        & > .company-image-container {
          margin-top: 24px;
          display: flex;
          align-items: center;
          margin-bottom: 41px;
          gap: 16px;
          & > img {
            height: 40px;
            width: 40px;
          }
          & > .company-name {
            font-weight: 700;
            font-size: 32px;
            line-height: 110%;
            color: #2c3941;
          }
        }
        & > .property-container {
          display: flex;
          flex-direction: column;
          row-gap: 18px;
          & .property-row {
            display: flex;
            justify-content: space-between;
            line-height: 120%;
            & .property-column-left {
              font-weight: 400;
              font-size: 14px;
              color: #545a5e;
            }
            & .property-column-right {
              font-weight: 700;
              font-size: 16px;
              color: #f53b4d;
            }
          }
        }
      }
      & > button {
        width: auto;
        padding: 16px 24px;
      }
      @media (max-width: breakpoints.$breakpoint-large-screen) {
        width: 100%;
        max-width: none;
        & > button {
          width: 100%;
        }
      }
    }
  }
}

@media print {
  @page {
    size: portrait;
  }
  .navigation {
    display: none;
  }
  .footer {
    display: none;
  }
  .payment-summary-wrapper-left {
    & .button-container {
      display: none;
    }
  }
  .payment-summary-wrapper-right {
    & .payment-company-card {
      margin-top: 100px;
    }
    & button {
      display: none;
    }
  }
}

.payment-upload-doc-user-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.payment-upload-doc-user-table th,
.payment-upload-doc-user-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.payment-upload-doc-user-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.payment-upload-doc-user-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.payment-upload-doc-user-table tr:hover {
  background-color: #f1f1f1;
}

.payment-upload-doc-user-table-row__caution-row {
  background-color: colors.$blue-charlie;

  & td {
    color: colors.$white;
  }

  &:hover {
    background-color: colors.$blue-charlie !important;
    color: colors.$white;
  }
}
