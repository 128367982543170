@use '../../global-styles/breakpoints';
@use '../../global-styles/colors';
@use '../../global-styles/mixins';

.profile-tab-container {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    flex-direction: column;
  }
}

.button-top-margin {
  margin-top: 64px;
}
.general-input {
  &-container {
    width: 42%;
    & > .input-label {
      margin-top: 0px;
    }
    @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
      width: 100%;
    }
  }
  &-label {
    @include mixins.font(700);
    margin-top: 8px;
    font-size: 14px;
    color: black;
  }
  &-select {
    margin: 0px;
  }
  &-error {
    @include mixins.font(500);
    font-size: 12px;
    line-height: 140%;
    color: colors.$error;
  }
  &-info {
    @include mixins.font(500);
    font-size: 12px;
    line-height: 140%;
    color: colors.$light-gray-echo;

    .mb-8 {
      margin-bottom: 8px;
    }
  }
}

.PhoneInput {
  @include mixins.font();
  padding: 0px 16px;
  border: 1px solid colors.$light-gray-delta;
  border-radius: 8px;
  background-color: colors.$white;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 120%;
  &:hover {
    border: 1px solid colors.$dark-gray-alpha;
  }
  &:focus-visible {
    outline: none;
    border: 1px solid colors.$dark-gray-alpha;
  }
  & .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  & .PhoneInputInternationalIconGlobe {
    display: none;
  }
}
.PhoneInputInput {
  @include mixins.font();
  padding: 17px 0px;
  border-style: none;
  width: 100%;
  color: colors.$light-gray-echo;
  &:focus-visible {
    outline: none;
    border-style: none;
    color: colors.$dark-gray-bravo;
  }
}

.rm-details-container {
  margin-bottom: 30px;

  & .payment-warning-container-full {
    max-width: 100%;
    @media (max-width: breakpoints.$breakpoint-large-screen) {
      max-width: -webkit-fill-available !important;
    }
  }

  & .email-link {
    font-size: 14px;
  }
}
