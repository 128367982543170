@use '../../../global-styles/mixins';
@use '../../../global-styles/colors';
@use '../../../global-styles/breakpoints';

.footer {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 96px 70px 48px;
  bottom: 0px;
  background-color: colors.$white;
  border-top: 1px solid colors.$light-gray-alpha;
  margin-top: 48px;
  column-gap: 96px;
  @media (max-width: breakpoints.$breakpoint-large-screen) {
    gap: 48px;
    flex-direction: column;
    padding: 70px 25px;
  }
  &-link-container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    justify-content: flex-start;
    width: max-content;
    & > a {
      text-decoration: none;
      display: block;
      width: max-content;
    }
    &-group {
      display: flex;
      gap: 96px;
      @media (max-width: breakpoints.$breakpoint-large-screen) {
        gap: 48px;
      }
    }
  }
  &-link-heading {
    @include mixins.font(700);
    font-size: 24px;
    line-height: 110%;
    letter-spacing: -0.01em;
    text-transform: capitalize;
    color: colors.$dark-gray-bravo;
    margin-bottom: 8px;
  }
  &-logo-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 40%;
    & > p {
      color: colors.$dark-gray-alpha;
    }

    &-logo {
      min-width: 150px;
      max-width: 250px;
    }

    @media (max-width: breakpoints.$breakpoint-large-screen) {
      flex-direction: row;
      width: auto;
    }
  }
  &-signup-main-container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    & > p {
      color: colors.$dark-gray-alpha;
    }
  }
  &-signup-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    & .signup-button {
      height: 50px;
      width: 130px;
      @media (max-width: breakpoints.$breakpoint-large-screen) {
        width: auto;
      }
    }
    @media (max-width: breakpoints.$breakpoint-large-screen) {
      flex-direction: column;
    }
  }
  &-disclaimer {
    @include mixins.font(700);
    display: flex;
    flex-direction: column;
    padding: 0px 70px;
    font-size: 10px;
    gap: 8px;
    color: colors.$light-gray-echo;
    margin-bottom: 8px;
    @media (max-width: breakpoints.$breakpoint-large-screen) {
      padding: 0px 40px;
    }
  }
}

ol {
  &.disclaimer {
    li {
      padding-left: 4px;
    }

    & .lower-roman {
      list-style-type: lower-roman;
    }
  }
}
