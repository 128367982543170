@use '../../global-styles/colors';
@use '../../global-styles/mixins';
@use '../../global-styles/breakpoints';

.nav-link {
  @include mixins.font(700);
  text-decoration: none;
  margin-right: 25px;
  padding: 8px 24px;
  border-radius: 10px;

  &.active {
    background-color: colors.$blue-charlie;
    color: colors.$white;
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-bottom: 5px;
    margin-right: 0px;
  }
}

.nav-link-footer {
  padding: 8px 4px;
}

.nav {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.dropdown {
  width: 100%;

  & :hover {
    cursor: pointer;
  }

  & > button {
    border: 0;
    background-color: colors.$blue-charlie;
    color: colors.$white;
  }

  & .dropdown-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.99);
    border: 1px solid colors.$light-gray-alpha;
    border-top: 0;
    border-radius: 5px;

    &.show {
      display: flex;
    }

    & > a {
      width: 100%;
      margin: 5px 0;
      text-decoration: none;
      color: colors.$dark-gray-alpha;
      border-bottom: 1px;
    }
  }

  & .dropdown-arrow {
    position: absolute;
    right: 20px;

    & path {
      fill: colors.$white;
    }
  }
}
