@use '../../../global-styles/colors';
@use '../../../global-styles/mixins';
@use '../../../global-styles/breakpoints';

.stepper-wrapper {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: '';
  border-bottom: 2px solid colors.$light-gray-india;
  width: 100%;
  top: 29px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: '';
  border-bottom: 1px solid colors.$light-gray-alpha;
  width: 100%;
  top: 29px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  background: colors.$white;
  margin-bottom: 6px;
}

.step-counter {
  @include mixins.font(700);
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  font-size: 24px;
  line-height: 110%;
  color: colors.$dark-gray-bravo;
  text-align: center;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: colors.$green-charlie;
}

.stepper-item.completed::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid colors.$green-charlie;
  width: 100%;
  top: 29px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.basic-stepper-wrapper {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  padding: 32px 0px;
  @media (max-width: breakpoints.$breakpoint-extra-large-screen) {
    width: 100%;
    border: 0;
  }
}
.basic-stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.basic-stepper-item::before {
  position: absolute;
  content: '';
  border-bottom: 2px solid colors.$light-gray-alpha;
  width: 100%;
  top: 5px;
  left: -50%;
  z-index: 2;
}

.basic-stepper-item::after {
  position: absolute;
  content: '';
  border-bottom: 2px solid colors.$light-gray-alpha;
  width: 100%;
  top: 5px;
  left: 50%;
  z-index: 2;
}

.basic-stepper-item .basic-step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  background: colors.$light-gray-hotel;
  border-radius: 4px;
  margin-bottom: 6px;
}
.basic-step-name {
  @include mixins.font(700);
  font-size: 16px;
  line-height: 120%;
  color: colors.$dark-gray-alpha;
  width: 180px;

  text-align: center !important;
}
.basic-step-counter.completed {
  background: colors.$blue-alpha;
}

.basic-step-counter.active {
  background: colors.$dark-gray-bravo;
}

.basic-stepper-item:not(.stepper-item-mobile):first-child::before {
  content: none;
}

.basic-stepper-item:not(.stepper-item-mobile):last-child::after {
  content: none;
}

.stepper-item-mobile.first-stepper-item::before {
  content: none;
}

.stepper-item-mobile.last-stepper-item::after {
  content: none;
}

.basic-stepper-status {
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: colors.$dark-gray-alpha;
  margin-top: 9px;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
}
.inactive-status {
  opacity: 0.5;
}
