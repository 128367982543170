@use '../../../global-styles/breakpoints';

@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/_variables';
@import '../../../../node_modules/bootstrap/scss/_mixins';
@import '~bootstrap/scss/_carousel.scss';

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 50px;
  height: 50px;
}

.carousel {
  margin-top: 50px;
}

.carousel-inner {
  text-align: center;
  height: 400px;
  border-radius: 16px;
}

.react-multi-carousel-list {
  width: 70%;
  right: -200px;
  z-index: 10;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 100%;
    right: 0;
  }

  @media (max-width: breakpoints.$breakpoint-mobile) {
    width: 100%;
    right: 0;
  }
}

.react-multi-carousel-item {
  overflow: hidden;
}

.react-multi-carousel-dot {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &-list {
    width: 70%;
    left: calc(30% + 200px) !important;
    bottom: -30px !important;
    z-index: 10;

    @media (max-width: breakpoints.$breakpoint-tablet) {
      left: auto !important;
      right: auto !important;
      width: auto !important;
    }
  }

  button {
    border: none !important;
    background-color: #cdcdcd;

    height: 6px !important;
    width: 6px !important;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    width: 100%;
    left: 0 !important;
  }

  @media (max-width: breakpoints.$breakpoint-mobile) {
    width: 100%;
    left: 0 !important;
  }
}

.react-multi-carousel-dot--active button {
  height: 8px !important;
  width: 8px !important;
}

.homepage-carousel-item {
  overflow: hidden;

  padding: 0 10px;

  img {
    width: 100%;

    border-radius: 10px;
  }

  @media (max-width: breakpoints.$breakpoint-tablet) {
    padding: 0 10px;
  }

  @media (max-width: breakpoints.$breakpoint-mobile) {
    padding: 0;
  }
}
