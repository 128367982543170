@use '../../../global-styles/colors';

.tool-tip-container {
  padding: 10px;
  margin: 5px;
  width: 250px;
  border-radius: 5px;
  background-color: colors.$light-gray-alpha;
  color: colors.$dark-gray-bravo;
}
.width-fit-content {
  width: fit-content;
}
