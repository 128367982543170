@use '../../global-styles/colors';
@use '../../global-styles/breakpoints';

.details-form-container {
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  padding: 32px;
}

.second-input-layout {
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-top: 0px;
  }
  @media (max-width: breakpoints.$breakpoint-large) {
    margin-top: 0px;
  }
}

.legal-agreement-wrapper {
  background: colors.$white;
  border: 1px solid colors.$light-gray-alpha;
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: breakpoints.$breakpoint-tablet) {
    text-align: center;
  }
}

.legal-agreement-file-upload-container {
  & > .input-file-main-container {
    border: none;
    padding-left: 0px;

    & .label-text {
      display: none;
    }
  }
}

.date-picker {
  width: 100%;
}
