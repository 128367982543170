@use '../../global-styles/colors';
@use '../../global-styles/breakpoints';

.slider-container {
  overflow-x: auto;
}

.toolbar-layout {
  display: grid;
  grid-template-columns: 3fr 1.2fr 1.3fr;
  width: 91vw;
  .sort-reset {
    display: flex;
    align-items: center;
    gap: 12px;
    & button {
      height: 52px;
    }
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 90vw;
    .sort-reset {
      display: flex;
      gap: 20px;
    }
  }
}

.project-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 90vw;
  margin-bottom: 32px;
  column-gap: 8px;
  row-gap: 24px;
}

.last-mnt {
  text-align: left;
  margin: 48px 0;
}

.slider-btn-container {
  display: flex;
  gap: 24px;
  margin-right: 20px;
  p {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    padding: 12px 12px;
    border: 1px solid colors.$light-gray-alpha;
    border-radius: 8px;
    transition: all 300ms ease;
    &:hover {
      background-color: colors.$light-gray-alpha;
    }
    &:active {
      color: colors.$white;
      background-color: colors.$light-gray-echo;
    }
  }
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-bottom: 24px;
  }
}

.slider-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  @media (max-width: breakpoints.$breakpoint-mobile) {
    justify-content: center;
  }
}

.reset-btn {
  max-width: 88px;
}

.search-input {
  height: 54px;
  margin: 8px 0px;
  & input {
    padding-left: 48px;
  }
  &-icon {
    margin-left: 19px;
    position: absolute;
    margin-top: 18px;
  }
}

.projects-gray-background-image {
  background: url('../../../public/assets/joel-filipe-PFIeJh17SZo-unsplash\ 1\ \(1\).png') no-repeat;
  background-size: cover;
  border-radius: 16px;
  height: 324px;
  margin-bottom: 120px;
  width: -webkit-fill-available;
  width: -moz-available;
  margin-left: -60px;
  margin-right: -60px;
  @media (max-width: breakpoints.$breakpoint-tablet) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.browse-heading-text {
  margin-bottom: 48px;
  text-align: center;
  letter-spacing: -0.02em;
}

.projects-pagination-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  &-info {
    flex-grow: 0.6;
    text-align: left;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    color: #545a5e;
    width: max-content;
  }
  & .pagination-container {
    margin: 0px;
    flex-grow: 1;
    justify-content: flex-start;
  }
}

.last-minute-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 120px;
  & > div {
    width: 100%;
  }
}
